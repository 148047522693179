import React from "react";
import "./partnerSection.scss";

import BlueBird from "../../../assets/images/blue.png";
import Hansa from "../../../assets/images/hre.jpeg";
import Ongel from "../../../assets/images/ongel.jpg";
import Jota from "../../../assets/images/jota.jpeg";
import Hauke from "../../../assets/images/hauke.jpeg";

import { NavLink } from "react-router-dom";
export default function Partner() {
  return (
    <div>
      <div className="partner-section-all-content-alignment">
        <div className="container-md">
          <div className="page-title">
            <h3
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Partner
            </h3>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Diese Unternehmen vertrauen Renderkings.
            </p>
            <div className="line-center-alignment">
              <div className="line"></div>
            </div>
          </div>
        </div>
        <div className="container-lg-3">
          <div className="all-flex-image-alignment">
            <div className="all-flex">
              <div>
                <a href="https://www.hauke-molter.de">
                  <img src={Hauke} alt="Icon" />
                </a>
              </div>
              <div>
                <a href="https://bluebirdfacility.de">
                  <img src={BlueBird} alt="GenslerIcon" />
                </a>
              </div>
              <div>
                <a href="https://www.hre-immobilien.de">
                  <img src={Hansa} alt="NBBJBlackLogoIcon" />
                </a>
              </div>
              <div>
                <a href="https://oengel.de">
                  <img src={Ongel} alt="LahdelmaIcon" />
                </a>
              </div>
              <div>
                <a href="https://jota-gamma.de/">
                  <img src={Jota} alt="SomIcon" />
                </a>
              </div>
            </div>
          </div>
          <div className="mobile-view-all-image-show">
            <div className="mobile-flex">
              <div>
                <a href="https://www.hauke-molter.de">
                  <img src={Hauke} alt="Icon" />
                </a>
              </div>
              <div>
                <a href="https://bluebirdfacility.de">
                  <img src={BlueBird} alt="GenslerIcon" />
                </a>
              </div>
              <div>
                <a href="https://www.hre-immobilien.de">
                  <img src={Hansa} alt="NBBJBlackLogoIcon" />
                </a>
              </div>
              <div>
                <a href="https://oengel.de">
                  <img src={Ongel} alt="LahdelmaIcon" />
                </a>
              </div>
              <div>
                <a href="https://jota-gamma.de/">
                  <img src={Jota} alt="SomIcon" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-lg-3">
          <div className="sub-heading-style">
            <h4
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Jetzt anfragen
            </h4>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Wir freuen uns sehr, wenn Sie uns über “Anfrage starten” eine
              Nachricht schicken, oder uns anrufen unter <br />
              <a href="tel:0421 64 85 29 66"> 0421 64 85 29 66</a>
            </p>
            <div className="line-center-alignment">
              <div className="line"></div>
            </div>
            <div className="md-button">
              <NavLink to="/kontakt-form">
                <button
                  className="animate__animated animate__slideInUp wow"
                  data-wow-offset="100">
                  Anfrage starten
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
