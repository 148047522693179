import React, { useEffect, useState } from "react";
import "./kontakt.scss";
import { Router, useHistory } from "react-router-dom";
import ContactBanner from "../../assets/images/contact.webp";
import axios from "axios";
import { forEmail } from "../../config";
import Swal from "sweetalert2";

export default function Kontakt() {
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [außenansichten, setAußenansichten] = useState<any>(false);
  const [innenansichten, setInnenansichten] = useState<any>(false);
  const [datenschutzer, setDatenschutzer] = useState<any>(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validateFormforAnfrage()) {
      const DataToSend = {
        from: "noreply@jesamconsulting.com",
        to: forEmail,
        subject: "Render Kings",
        html: `    
            <strong>Render Kings</strong> <br />
            <strong>Name: </strong> ${formData.Name} <br />
            <strong>E-Mail: </strong> ${formData.email} <br />
            <strong>Telefon: </strong> ${formData.Telefon} <br />
            <strong>Betreff: </strong> ${formData.Betreff} <br />
            <strong>Nachricht: </strong> ${formData.Nachricht} <br />
            <strong>außenansichten: </strong> ${
              formData.aubNumber ? formData.aubNumber : 0
            } <br />
            <strong>Innenansichten: </strong> ${
              formData.inneNumber ? formData.inneNumber : 0
            } <br />           
        `,
      };

      const remoteServerUrl =
        "https://jesam-mail-api.herokuapp.com/api/sendemail";

      axios
        .post(remoteServerUrl, DataToSend)
        .then((res) => {
          console.log("Message send success");
          setFormData({
            Name: "",
            email: "",
            Telefon: "",
            Betreff: "",
            Nachricht: "",
            aubNumber: "",
            inneNumber: "",
          });
          setAußenansichten(false);
          setInnenansichten(false);
          setDatenschutzer(false);
          Swal.fire({
            title: "Vielen Dank \n" + "für Ihre Anfrage",
            icon: "success",
            confirmButtonText: "Bestätigen",
          });
          console.log(res);
        })
        .catch((err) => {
          Swal.fire({
            title: "Something happened",
            text: "Please try later",
            icon: "error",
            confirmButtonText: "Bestätigen",
          });
          console.log(err);
        });
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.type === "checkbox" && e.target.name === "außenansichten") {
      setAußenansichten(!außenansichten);
    }
    if (e.target.type === "checkbox" && e.target.name === "innenansichten") {
      setInnenansichten(!innenansichten);
    }
    if (e.target.type === "checkbox" && e.target.name === "datenschutzer") {
      setDatenschutzer(!datenschutzer);
    }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateFormforAnfrage = () => {
    let formIsValid = true;
    let errors: any = {};
    if (formData && !formData.Name?.trim()) {
      formIsValid = false;
      errors["Name"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.email) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (!regex.test(formData.email)) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Telefon) {
      formIsValid = false;
      errors["Telefon"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Betreff?.trim()) {
      formIsValid = false;
      errors["Betreff"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Nachricht?.trim()) {
      formIsValid = false;
      errors["Nachricht"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (außenansichten === true && !formData.aubNumber) {
      formIsValid = false;
      errors["aubNumber"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (innenansichten === true && !formData.inneNumber) {
      formIsValid = false;
      errors["inneNumber"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (datenschutzer === false) {
      formIsValid = false;
      errors["datenschutzer"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    setErrors(errors);
    return formIsValid;
  };

  return (
    <div>
      <div className="contact-banner-content-alignment">
        <div className="container-lg-5">
          <img src={ContactBanner} alt="ContactBanner" />
        </div>
      </div>
      <div className="container-md">
        <div className="contact-box-center-alignment-for-page">
          <div className="contact-box">
            <div className="contact-child-box">
              <h1>Anfrage</h1>
              {/* <form > */}
              <div className="input">
                {/* <input
                  type="text"
                  name="Name"
                  placeholder="Name*"
                  value={formData.Name && formData.Name}
                  onChange={(e) => handleChange(e)}
                />
                <span style={{ fontSize: "10px", color: "red" }}>
                  {errors.Name}
                </span> */}
                <div className="floating-form">
                  <div className="floating-label">
                    <input
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      id="Name"
                      name="Name"
                      value={formData.Name && formData.Name}
                      onChange={(e) => handleChange(e)}
                    />

                    <label>Name</label>
                    <p style={{ fontSize: "10px", color: "red" }}>
                      {errors.Name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="input">
                {/* <input
                  type="text"
                  name="email"
                  placeholder="E-mail"
                  value={formData.email && formData.email}
                  onChange={(e) => handleChange(e)}
                />
                <span style={{ fontSize: "10px", color: "red" }}>
                  {errors.email}
                </span> */}
                <div className="floating-form">
                  <div className="floating-label">
                    <input
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      id="email"
                      name="email"
                      value={formData.email && formData.email}
                      onChange={(e) => handleChange(e)}
                    />

                    <label>E-Mail</label>
                    <p style={{ fontSize: "10px", color: "red" }}>
                      {errors.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="input">
                {/* <input
                  type="number"
                  name="Telefon"
                  placeholder="Telefon"
                  value={formData.Telefon && formData.Telefon}
                  onChange={(e) => handleChange(e)}
                />
                <span style={{ fontSize: "10px", color: "red" }}>
                  {errors.Telefon}
                </span> */}
                <div className="floating-form">
                  <div className="floating-label">
                    <input
                      className="floating-input"
                      type="number"
                      placeholder=" "
                      id="Telefon"
                      name="Telefon"
                      value={formData.Telefon && formData.Telefon}
                      onChange={(e) => handleChange(e)}
                    />

                    <label>Telefon</label>
                    <p style={{ fontSize: "10px", color: "red" }}>
                      {errors.Telefon}
                    </p>
                  </div>
                </div>
              </div>
              <div className="input">
                {/* <input
                  type="text"
                  name="Betreff"
                  placeholder="Betreff*"
                  value={formData.Betreff && formData.Betreff}
                  onChange={(e) => handleChange(e)}
                />
                <span style={{ fontSize: "10px", color: "red" }}>
                  {errors.Betreff}
                </span> */}
                <div className="floating-form">
                  <div className="floating-label">
                    <input
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      id="Betreff"
                      name="Betreff"
                      value={formData.Betreff && formData.Betreff}
                      onChange={(e) => handleChange(e)}
                    />

                    <label>Betreff</label>
                    <p style={{ fontSize: "10px", color: "red" }}>
                      {errors.Betreff}
                    </p>
                  </div>
                </div>
              </div>
              <div className="input">
                {/* <textarea
                  placeholder="Nachricht"
                  name="Nachricht"
                  value={formData.Nachricht && formData.Nachricht}
                  onChange={(e) => handleChange(e)}
                ></textarea>
                <span style={{ fontSize: "10px", color: "red" }}>
                  {errors.Nachricht}
                </span> */}
                <div className="floating-form">
                  <div className="floating-label">
                    <textarea
                      className="floating-input"
                      placeholder=" "
                      id="Nachricht"
                      name="Nachricht"
                      value={formData.Nachricht && formData.Nachricht}
                      onChange={(e) => handleChange(e)}></textarea>

                    <label>Nachricht</label>
                    <p style={{ fontSize: "10px", color: "red" }}>
                      {errors.Nachricht}
                    </p>
                  </div>
                </div>
              </div>
              <div className="all-checkbox-content-alignment">
                <div className="checkbox checkbox-center-alignment">
                  <div>
                    <input
                      type="checkbox"
                      id="Donuts2"
                      name="außenansichten"
                      checked={außenansichten === true ? true : false}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="Donuts2"></label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <p>Außenansichten </p>
                    {außenansichten === true && (
                      <div className="abc-button">
                        <input
                          type="number"
                          name="aubNumber"
                          placeholder="0"
                          value={formData.aubNumber && formData.aubNumber}
                          onChange={(e) => handleChange(e)}
                        />
                        {/* <div className="icon-alignment">
                          <img src={UpDownIcon} alt="UpDownIcon" />
                        </div> */}
                      </div>
                    )}
                    <span style={{ fontSize: "10px", color: "red" }}>
                      {errors.aubNumber}
                    </span>
                  </div>
                </div>
                <div className="checkbox checkbox-center-alignment">
                  <div>
                    <input
                      type="checkbox"
                      id="Innenansichten"
                      name="innenansichten"
                      checked={innenansichten === true ? true : false}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="Innenansichten"></label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <p>Innenansichten </p>
                    {innenansichten === true && (
                      <div
                        className="abc-button"
                        style={{ position: "relative", left: "4px" }}>
                        <input
                          type="number"
                          name="inneNumber"
                          placeholder="0"
                          value={formData.inneNumber && formData.inneNumber}
                          onChange={(e) => handleChange(e)}
                        />
                        {/* <div className="icon-alignment">
                          <img src={UpDownIcon} alt="UpDownIcon" />
                        </div> */}
                      </div>
                    )}
                    <span style={{ fontSize: "10px", color: "red" }}>
                      {errors.inneNumber}
                    </span>
                  </div>
                </div>
                <div className="checkbox checkbox-start-alignment">
                  <div>
                    <input
                      type="checkbox"
                      id="datenschutzer"
                      name="datenschutzer"
                      checked={datenschutzer === true ? true : false}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor="datenschutzer"></label>
                  </div>
                  <div>
                    <p>
                      Ich habe die AGB und Datenschutzbestimmungen gelesen und
                      akzeptiert.
                    </p>
                  </div>
                </div>
                <span style={{ fontSize: "10px", color: "red" }}>
                  {errors.datenschutzer}
                </span>
              </div>
              <div className="send-full-width" onClick={(e) => handleSubmit(e)}>
                <button>Senden</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
