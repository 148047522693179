import React, { useEffect } from "react";
import "./works.scss";
import WorkBanner from "../../../assets/images/work-banner.png";
export default function Works() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="works-all-content-alignment">
        <div className="container-lg-5">
          <img src={WorkBanner} />
        </div>
      </div>
      <div className="container-sm">
        <div className="work-box-center-alignment">
          <div className="work-box">
            <div className="first-text">
              <h1>Meet us here.</h1>
              <h2>1036 Bremen, Teststraße 8., Deutschland</h2>
              <div className="line-center-alignment">
                <div className="line"></div>
              </div>
            </div>
            <div className="sec-text">
              <p>Información de ventas y proyectos</p>
              <a>sales@render-kings.com</a>
            </div>
            <div className="three-section">
              <div className="line-center-alignment">
                <div className="line"></div>
              </div>
              <h2>Bremen</h2>
              <p>Hauke</p>
              <a>+49 30 103 1393</a>
            </div>
            <div className="last-sec">
              <span>
                Anfrage | <a>info@render-kings.com</a>
              </span>
              <span>
                Beratung | hauke<a>@render-kings.com</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
