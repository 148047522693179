import React, { ChangeEvent, useState } from "react";
import { forEmail } from "../../config";
import UploadImage from "../../assets/icons/upload.svg";
import "./step3.scss";
import axios from "axios";
import { useGlobalContext } from "../../context";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { useHistory } from "react-router-dom";

export default function Step3(props: any) {
  const history = useHistory();

  const {
    formData,
    handleUploadFiles,
    setFormData,
    errors,
    setErrors,
    step,
    setStep,
    fileData,
    isChecked,
    setFileData,
    setIsChecked,
  } = props;

  const { job } = useGlobalContext();

  const [cv64, setcv64] = useState(null);
  const [selectCv, setSelectCv] = useState(". . .");
  const [pathCv, setPathCv] = useState(null);

  const [an64, setAn64] = useState(null);
  const [selectAn, setSelectAn] = useState(". . .");
  const [pathAn, setPathAn] = useState(null);

  const [foto, setFoto] = useState(null);
  const [selectFoto, setSelectFoto] = useState(". . .");
  const [pathFoto, setPathFoto] = useState(null);

  const [otro64, setOtro64] = useState(null);
  const [selectOtro, setSelectOtro] = useState(". . .");
  const [pathOtro, setPathOtro] = useState(null);

  const [wei, setWei] = useState(null);
  const [selectWei, setSelectWei] = useState(". . .");
  const [pathWei, setPathWei] = useState(null);

  const validateStep3 = () => {
    let formIsValid = true;
    let errors: any = {};
    if (fileData && !fileData.Lebenslauf) {
      formIsValid = false;
      errors["Lebenslauf"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (isChecked !== "true") {
      formIsValid = false;
      errors["isChecked"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleFile = (
    event: any,
    setSelection: any,
    setPath: any,
    setBloob: any
  ) => {
    if (event.target.files && event.target.files[0]) {
      const fichero_path_usuario = event.target.files[0];
      setSelection(fichero_path_usuario.name);

      setPath(fichero_path_usuario);

      var reader = new FileReader();
      reader.onload = function (event: any) {
        let fichero: any = event?.target.result.toString();
        let base64data = btoa(fichero);
        setBloob(base64data);
      };
      reader.readAsBinaryString(fichero_path_usuario);
    }
  };

  const handleSubmit = async () => {
    let attachments = [];

    if (selectCv != ". . .") {
      attachments.push({
        filename: selectCv,
        content: cv64,
      });
    }

    if (selectAn != ". . .") {
      attachments.push({
        filename: selectAn,
        content: an64,
      });
    }

    if (selectFoto != ". . .") {
      attachments.push({
        filename: selectFoto,
        content: foto,
      });
    }
    if (selectOtro != ". . .") {
      attachments.push({
        filename: selectOtro,
        content: otro64,
      });
    }

    if (selectWei != ". . .") {
      attachments.push({
        filename: selectWei,
        content: wei,
      });
    }

    const DataToSend = {
      from: "noreply@jesamconsulting.com",
      to: forEmail,
      attachments: attachments,
      subject: "Render Kings",
      html: `    
        <strong>Render Kings</strong> <br />
        <strong>Karriere:  ${job} </strong> <br />
        <strong>Name: </strong> ${formData.Name} <br />
        <strong>Vorname: </strong> ${formData.Vorname} <br />
        <strong>E-Mail: </strong> ${formData.Email} <br />
        <strong>Ort: </strong> ${formData.Ort} <br />
        <strong>Plz: </strong> ${formData.plz} <br />
        <strong>Strabe: </strong> ${formData.Strabe} <br />
        <strong>Gehaltsvorstellung: </strong> ${formData.Payment} <br />
        <strong>Wie haben Sie uns gefunden?: </strong> ${formData.Notice} <br />
        <strong>Frühester Eintritt: </strong> ${formData.Enter}  <br />
        `,
    };

    const remoteServerUrl =
      "https://jesam-mail-api.herokuapp.com/api/sendemail";

    axios
      .post(remoteServerUrl, DataToSend)
      .then((res) => {
        console.log("Message send success");
        setFormData({});
        setFileData({});
        history.push("/career");

        Swal.fire({
          title: "Vielen Dank \n" + "für Ihre Anfrage",
          icon: "success",
          confirmButtonText: "Bestätigen",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Something happened",
          text: "Please try later",
          icon: "error",
          confirmButtonText: "Bestätigen",
        });
        console.log(err);
      });
  };

  return (
    <div>
      <>
        <div className="anlagen-content-alignment">
          <div className="text-style">
            <h3>Anlagen</h3>
            <p>
              Du kannst hier entweder ein gesamtes Bewerbungs-Dokument oder
              einzelne Dokumente hochladen. Folgende Dateiformate sind möglich:
              PDF, JPG. Ein Einzeldokument darf die Größe von 10 MB nicht
              überschreiten. Bitte lade keine PDF- und Word-Dokumente hoch, die
              mit einem Passwort- oder Schreibschutz versehen sind.
            </p>
          </div>
          <div className="upload-grid">
            <div className="upload-grid-items">
              <h4>Lebenslauf *</h4>
              <div className="box-items">
                {fileData?.Lebenslauf ? (
                  fileData?.Lebenslauf?.name
                ) : (
                  <>
                    <div className="image-center-alignment">
                      <img src={UploadImage} alt="UploadImage" />
                    </div>
                    <h6>Dateien wählen</h6>
                    <span>Dateien hier her ziehen und ablegen</span>
                  </>
                )}
                <input
                  type="file"
                  name="Lebenslauf"
                  accept="application/pdf, image/jpeg"
                  max-size="10000"
                  onChange={(e) => {
                    handleUploadFiles(e);
                    handleFile(e, setSelectCv, setPathCv, setcv64);
                  }}
                />
              </div>
              <span style={{ fontSize: "10px", color: "red" }}>
                {errors.Lebenslauf}
              </span>
            </div>
            <div className="upload-grid-items">
              <h4>Anschreiben (optional)</h4>
              <div className="box-items">
                {fileData?.Anschreiben ? (
                  fileData?.Anschreiben?.name
                ) : (
                  <>
                    <div className="image-center-alignment">
                      <img src={UploadImage} alt="UploadImage" />
                    </div>
                    <h6>Dateien wählen</h6>
                    <span>Dateien hier her ziehen und ablegen</span>
                  </>
                )}
                <input
                  type="file"
                  name="Anschreiben"
                  accept="application/pdf, image/jpeg"
                  max-size="10000"
                  onChange={(e) => {
                    handleUploadFiles(e);
                    handleFile(e, setSelectAn, setPathAn, setAn64);
                  }}
                />
              </div>
            </div>
            <div className="upload-grid-items">
              <h4>Foto als JPG</h4>
              <div className="box-items">
                {fileData?.foto ? (
                  fileData?.foto?.name
                ) : (
                  <>
                    <div className="image-center-alignment">
                      <img src={UploadImage} alt="UploadImage" />
                    </div>
                    <h6>Dateien wählen</h6>
                    <span>Dateien hier her ziehen und ablegen</span>
                  </>
                )}
                <input type="file" />
                <input
                  type="file"
                  name="foto"
                  accept="application/pdf, image/jpeg"
                  max-size="10000"
                  onChange={(e) => {
                    handleUploadFiles(e);
                    handleFile(e, setSelectFoto, setPathFoto, setFoto);
                  }}
                />
              </div>
            </div>
            <div className="upload-grid-items">
              <h4>Zeugnisse</h4>
              <div className="box-items">
                {fileData?.Zeugnisse ? (
                  fileData?.Zeugnisse?.name
                ) : (
                  <>
                    <div className="image-center-alignment">
                      <img src={UploadImage} alt="UploadImage" />
                    </div>
                    <h6>Dateien wählen</h6>
                    <span>Dateien hier her ziehen und ablegen</span>
                  </>
                )}
                <input
                  type="file"
                  name="Zeugnisse"
                  accept="application/pdf, image/jpeg"
                  max-size="10000"
                  onChange={(e) => {
                    handleUploadFiles(e);
                    handleFile(e, setSelectOtro, setPathOtro, setOtro64);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="lg-upload-title">
            <h4>Weitere Dokumente</h4>
            <div className="box-items">
              {fileData?.Weitere ? (
                fileData?.Weitere?.name
              ) : (
                <>
                  <div className="image-center-alignment">
                    <img src={UploadImage} alt="UploadImage" />
                  </div>
                  <h6>Dateien wählen</h6>
                  <span>Dateien hier her ziehen und ablegen</span>
                </>
              )}
              <input
                type="file"
                name="Weitere"
                accept="application/pdf, image/jpeg"
                max-size="10000"
                onChange={(e) => {
                  handleUploadFiles(e);
                  handleFile(e, setSelectWei, setPathWei, setWei);
                }}
              />
            </div>
          </div>
        </div>
        <div className="checkbox-center-alignment flex space-x-4 items-center">
          <div className="mt-1">
            <input
              type="checkbox"
              id="Donuts4"
              name="isChecked"
              checked={isChecked === "true" ? true : false}
              onChange={(e) => {
                handleUploadFiles(e);
                setErrors({ ...errors, [e.target.name]: "" });
              }}
            />
            <label htmlFor="Donuts4"></label>
          </div>
          <p>
            Ich akzeptiere die elektronische Speicherung meiner Daten gemäß der{" "}
            <a>Datenschutzrichtlinien.</a> <a className="red">*</a>
            <span style={{ fontSize: "10px", color: "red" }}>
              {errors.isChecked}
            </span>
          </p>
        </div>
        <div className="md:col-span-2 mt-6">
          <div className="w-full flex justify-between">
            <div
              onClick={() => {
                setStep(step - 1);
              }}
              className="bg-black px-7 md:py-2 py-1.5 text-white hover:cursor-pointer md:min-w-[6.5rem]  min-w-[6rem] text-center">
              Zurück
            </div>
            <div
              onClick={() => {
                if (validateStep3()) {
                  handleSubmit();
                }
              }}
              className="bg-black px-7 md:py-2 py-1.5 text-white hover:cursor-pointer md:min-w-[6.5rem]  min-w-[6rem] text-center">
              Senden
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
