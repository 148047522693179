import React, { useEffect } from "react";
import "./career.scss";
import CardJob from "../../components/cardJob";
import { useGlobalContext } from "../../context";
import { useHistory } from "react-router-dom";

export default function Career() {
  const { job, setJob, place, setPlace, date, setSelectDate } =
    useGlobalContext();

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <div className="career-all-content-alignment">
        <div className="container-lg">
          <div className="page-title">
            <h1
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Karriere bei Renderkings
            </h1>
            <div className="line"></div>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Der Renderkings Hauptsitz befindet sich zentral im schönen Bremen.
              Von hier aus bearbeiten wir sowohl regionale als auch
              überregionale Projekte im Architektur- und Immobilienbereich, um
              unsere Kunden mit den passenden Visualisierungen zu unterstützen.
            </p>
          </div>
          <div className="grid">
            {data?.map((el: any, index: number) => {
              return (
                <div
                  onClick={() => {
                    setJob(el.title);
                    setPlace(el.location);
                    setSelectDate(el.date);
                    history.push("/bewerbung");
                  }}>
                  <CardJob {...el} key={index} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const data = [
  {
    title: "3D Generalist",
    location: "Bremen",
    description: "3D Artist | Animations | Bremen",
    date: "21. November 2022",
    text_contact: "Jetzt hier in wenigen Schritten bewerben.",
    // email: "info@renderkings.de",
  },
  {
    title: "3D Artist",
    location: "Bremen",
    description: "3D Artist | Animations | Bremen",
    date: "21. November 2022",
    text_contact: "Jetzt hier in wenigen Schritten bewerben.",
    // email: "info@renderkings.de",
  },
  {
    title: "Projektmanager",
    location: "Bremen, ab sofort",
    description: "3D Artist | Animations | Bremen",
    date: "21. November 2022",
    text_contact: "Jetzt hier in wenigen Schritten bewerben.",
    // email: "info@renderkings.de",
  },
];
