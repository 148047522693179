import React, { useState, ChangeEvent } from "react";
import { useGlobalContext } from "../../context";
import Step1 from "../../components/step/step1";
import "./bewerbung.scss";
import Step2 from "../../components/step/step2";
import Step3 from "../../components/step/step3";

export default function Bewerbung() {
  const { date, selectJob, job, setSelectDate, place, setPlace } =
    useGlobalContext();
  const [formData, setFormData] = useState<object>({});
  const [errors, setErrors] = useState<object>({});
  const [step, setStep] = useState<number>(1);
  const [fileData, setFileData] = useState<any>({});
  const [isChecked, setIsChecked] = useState("false");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleUploadFiles = (e: any) => {
    if (e.target.checked) {
      setIsChecked("true");
    } else {
      setIsChecked("false");
    }
    if (e.target.files?.[0]) {
      setFileData({ ...fileData, [e.target.name]: e.target.files?.[0] });
    }
    setErrors({ ...errors, [e.target.name]: "" });
  };

  return (
    <div className="lg:mx-40 lg:my-20 md:px-10 md:py-10 mx-6 py-8">
      <div className="w-full flex lg:space-x-16 md:space-x-10 space-x-8 justify-center">
        <div
          className={`border border-black hover:cursor-pointer ${
            step < 4 ? "bg-black text-white" : "bg-white text-black"
          } relative rounded-full p-4`}>
          <div className="font-bold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            1
          </div>
        </div>
        <div
          className={`border border-black hover:cursor-pointer ${
            step < 4 && step > 1 ? "bg-black text-white" : "bg-white text-black"
          } relative rounded-full p-4`}>
          <div className="font-bold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            2
          </div>
        </div>
        <div
          className={`border border-black hover:cursor-pointer ${
            step == 3 ? "bg-black text-white" : "bg-white text-black"
          } relative rounded-full p-4`}>
          <div className="font-bold absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            3
          </div>
        </div>
      </div>
      <div className="text-xl font-bold text-right my-6">{job}</div>
      <div className="border lg:px-14 lg:py-10 bg-[##f2f2f2] md:px-8 md:py-10 px-5 py-6">
        {step === 1 && (
          <Step1
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            errors={errors}
            step={step}
            setStep={setStep}
            setErrors={setErrors}
          />
        )}
        {step === 2 && (
          <Step2
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            errors={errors}
            step={step}
            setStep={setStep}
            setErrors={setErrors}
          />
        )}
        {step === 3 && (
          <Step3
            formData={formData}
            setFormData={setFormData}
            handleUploadFiles={handleUploadFiles}
            fileData={fileData}
            setFileData={setFileData}
            errors={errors}
            step={step}
            setStep={setStep}
            setErrors={setErrors}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
        )}
      </div>
    </div>
  );
}
