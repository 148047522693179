import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./carousel.scss";
import Angle from "../../assets/icons/angle.svg";
import AngleLeft from "../../assets/icons/angle-left.svg";
import Cancel from "../../assets/icons/cancel.svg";
import { isConstructorDeclaration } from "typescript";

export default function Corousel(props: any) {
  const { open, setOpen, images, page, direction, setPage } = props;

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [currentIndex,setCurrentIndex]=useState(0);

  const thumbRef = useRef<any>(null);
  const listThumbRef = useRef<any>(null);
  console.log("pacgae",page,currentIndex);
  useEffect(() => {
    setCurrentIndex(0)
    thumbRef.current.scrollLeft = 0;
    thumbRef.current.scrollLeft = 105 * page;
  }, [page]);

  const imageIndex = (page: number) => {
    if (page >= images.length) {
      return 0;
    } else if (page < 0) {
      return images.length - 1;
    } else {
      return page;
    }
  };

  const pagination = (newDirection: number) => {
    // setPage([imageIndex(page + newDirection), newDirection]);
  };

  function handleTouchStart(e: any) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e: any) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 80 && images.length > 0) {
      thumbRef.current.scrollLeft += 105;
      pagination(1);
    }

    if (touchStart - touchEnd < -80 && images.length > 0) {
      thumbRef.current.scrollLeft -= 105;
      pagination(-1);
    }
  }

  return (
    <div data-show={open ? true : false} className="viewr-container">
      <motion.div
        onClick={() => {
          setOpen(false);
        }}
        whileHover={{
          scale: 1.1,
          transition: {
            duration: 0.5,
          },
        }}
        whileTap={{
          scale: 0.9,
        }}
        className="cancel">
        <img src={Cancel} alt="" />
      </motion.div>
      <motion.div
        whileHover={{
          scale: 1.1,
          transition: {
            duration: 0.5,
          },
        }}
        whileTap={{
          scale: 0.9,
        }}
        onClick={() => {
          thumbRef.current.scrollLeft -= 105;
          setCurrentIndex(currentIndex === 0 ? images[page]?.allImg?.length - 1 :currentIndex -1)
        }}
        className="navigation arrow-prev">
        <img src={AngleLeft} alt="" />
      </motion.div>
      <motion.div
        whileHover={{
          scale: 1.1,
          transition: {
            duration: 0.5,
          },
        }}
        whileTap={{
          scale: 0.9,
        }}
        onClick={() => {
          thumbRef.current.scrollLeft += 105;

          setCurrentIndex(currentIndex === images[page]?.allImg?.length - 1 ?  0 :currentIndex + 1)
        }}
        className="navigation arrow-next">
        <img src={Angle} alt="" />
      </motion.div>
      <div className="container-all">
        <div className="carousel-container">
          <AnimatePresence initial={false} custom={direction}>
            <motion.img
              className="image-carousel"
              key={page}
              src={images[page]?.allImg[currentIndex]}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              custom={direction}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0,
                },
              }}
              transition={{
                x: { type: "spring", stiffness: 300, damping: 300 },
                opacity: { duration: 1 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
            />
          </AnimatePresence>
        </div>
        <div className="description">
          <p className="description-title">
            {images[imageIndex(page)].project}
          </p>
          <p>{images[imageIndex(page)].client}</p>
        </div>
        <div ref={thumbRef} id="ras" className="container-thumbs">
          <div ref={listThumbRef} className="list-thumb">
            {images[page]?.allImg.map((el: any, index: number) => {
              return (
                <img
                  src={el}
                  key={index}
                  onClick={() => {
                    // setPage([index, 1]);
                    setCurrentIndex(index)
                  }}
                  alt=""
                  className={`thumb ${
                    index == currentIndex ? "active" : ""
                  }`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
