import React from "react";
import "./cardJob.scss";

export default function CardJob(props: any) {
  return (
    <div className="grid-items hover:cursor-pointer">
      <h1 className="text-3xl">{props.title}</h1>
      <h1 className="text-3xl"> (m/w/d)</h1>
      <h2>{props.location}</h2>
      <div className="line"></div>
      <div className="sec-text">
        <h3>{props.description}</h3>
        <span>{props.date}</span>
        <p>
          {props.text_contact}
          <a href={`mailto:${props.email}`}> {props.email}</a>
        </p>
      </div>
    </div>
  );
}
