import React from "react";
import "./footer.scss";
import FacebookIcon from "../../assets/icons/facebook.svg";
import instagramIcon from "../../assets/icons/insta.svg";
import YoutubeIcon from "../../assets/icons/youtube.svg";
import VIcon from "../../assets/icons/v-icon.svg";
import TwitterIcon from "../../assets/icons/twitter.svg";
import LinkdinIcon from "../../assets/icons/linkdin.svg";
import { NavLink } from "react-router-dom";
export default function Footer() {
  return (
    <>
      <div className="container-lg-4">
        <div className="footer-alignment">
          <div className="container-links-footer">
            <p>
              Copyright © 2022 <br className="md:hidden block" /> JESAM
              Consulting GmbH. <br className="md:hidden block" /> Alle Rechte
              vorbehalten.
            </p>
            <div>|</div>
            <NavLink to="/impressum">Impressum</NavLink>
            <div>|</div>
            <NavLink to="/datenschutz">Datenschutz</NavLink>
          </div>
          <div>
            <a
              href="https://www.facebook.com/profile.php?id=100087621848729"
              target="_blank">
              <img src={FacebookIcon} alt="FacebookIcon" />
            </a>
            <a
              href="https://instagram.com/renderkings.de?igshid=YmMyMTA2M2Y="
              target="_blank">
              <img src={instagramIcon} alt="instagramIcon" />
            </a>
            <a
              href="https://www.linkedin.com/company/renderkings/"
              target="_blank">
              <img src={LinkdinIcon} alt="LinkdinIcon" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
