import React from "react";

export default function Step1(props: any) {
  const { formData, handleChange, errors, setErrors, step, setStep } = props;
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validateStep1 = () => {
    let formIsValid = true;
    let errors: any = {};
    if (formData && !formData.Name?.trim()) {
      formIsValid = false;
      errors["Name"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Vorname?.trim()) {
      formIsValid = false;
      errors["Vorname"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if ((formData && !formData.Email?.trim()) || !regex.test(formData.Email)) {
      formIsValid = false;
      errors["Email"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }

    if (formData && !formData.Ort?.trim()) {
      formIsValid = false;
      errors["Ort"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.plz?.trim()) {
      formIsValid = false;
      errors["plz"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Strabe?.trim()) {
      formIsValid = false;
      errors["Strabe"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }

    setErrors(errors);
    return formIsValid;
  };

  return (
    <div>
      <p className="text-3xl font-bold">Stammdaten</p>
      <div className="grid md:grid-cols-2 md:mt-10 lg:gap-x-28 mt-4">
        <div className="input">
          <div className="floating-form">
            <div className="floating-label">
              <input
                className="floating-input"
                type="text"
                placeholder=" "
                id="Name"
                name="Name"
                value={formData.Name && formData.Name}
                onChange={(e) => handleChange(e)}
              />
              <label>Name</label>

              <p style={{ fontSize: "10px", color: "red" }}>{errors.Name}</p>
            </div>
          </div>
        </div>
        <div className="input">
          <div className="floating-form">
            <div className="floating-label">
              <input
                className="floating-input"
                type="email"
                placeholder=" "
                id="Email"
                name="Email"
                value={formData.Email && formData.Email}
                onChange={(e) => handleChange(e)}
              />
              <label>E-Mail</label>
              <p style={{ fontSize: "10px", color: "red" }}>{errors.Email}</p>
            </div>
          </div>
        </div>
        <div className="input">
          <div className="floating-form">
            <div className="floating-label">
              <input
                className="floating-input"
                type="text"
                placeholder=" "
                id="Strabe"
                name="Strabe"
                value={formData.Strabe && formData.Strabe}
                onChange={(e) => handleChange(e)}
              />
              <label>Straße und Hausnummer</label>
              <p style={{ fontSize: "10px", color: "red" }}>{errors.Strabe}</p>
            </div>
          </div>
        </div>

        <div className="input md:row-start-1 md:col-start-2 row-start-2">
          <div className="floating-form">
            <div className="floating-label">
              <input
                className="floating-input"
                type="text"
                placeholder=" "
                id="Vorname"
                name="Vorname"
                value={formData.Vorname && formData.Vorname}
                onChange={(e) => handleChange(e)}
              />
              <label>Vorname</label>

              <p style={{ fontSize: "10px", color: "red" }}>{errors.Vorname}</p>
            </div>
          </div>
        </div>
        <div className="input">
          <div className="floating-form">
            <div className="floating-label">
              <input
                className="floating-input"
                type="text"
                placeholder=" "
                id="Ort"
                name="Ort"
                value={formData.Ort && formData.Ort}
                onChange={(e) => handleChange(e)}
              />
              <label>Ort</label>

              <p style={{ fontSize: "10px", color: "red" }}>{errors.Ort}</p>
            </div>
          </div>
        </div>

        <div className="input">
          <div className="floating-form">
            <div className="floating-label">
              <input
                className="floating-input"
                type="text"
                placeholder=" "
                id="plz"
                name="plz"
                value={formData.plz && formData.plz}
                onChange={(e) => handleChange(e)}
              />
              <label>PLZ</label>

              <p style={{ fontSize: "10px", color: "red" }}>{errors.plz}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="md:col-span-2 mt-6">
        <div className="w-full flex justify-end">
          <div
            onClick={() => {
              if (validateStep1()) {
                setStep(step + 1);
              }
            }}
            className="bg-black px-7 py-2 text-white hover:cursor-pointer">
            Weiter
          </div>
        </div>
      </div>
    </div>
  );
}
