import React, { useEffect, useRef, useState } from "react";
import "./home.scss";
import Slider from "react-slick";
import HomeBanner from "../../assets/images/home-banner1.webp";
import HomeBanner1 from "../../assets/images/0006.png";
import HomeBanner3 from "../../assets/images/00005.png";
import HomeBanner4 from "../../assets/images/0004.webp";
import HomeBanner5 from "../../assets/images/00073.png";
// import HomeBanner1 from "../../assets/images/GARDEN.svg";
import MobileBanner from "../../assets/images/home-banner1.webp";
import MobileBanner1 from "../../assets/images/0001.webp";
import MobileBanner2 from "../../assets/images/0003.webp";
import MobileBanner3 from "../../assets/images/0004.webp";
import SliderFooter from "./sliderFooter";
import { NavLink } from "react-router-dom";
import { useGlobalContext } from "../../context";

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div className="right-icon slide-arrow" onClick={onClick}>
      <i className="fa-solid fa-chevron-right fa-2x"></i>
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div className="left-icon slide-arrow" onClick={onClick}>
      <i className="fa-solid fa-chevron-left fa-2x"></i>
    </div>
  );
}
export default function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { completeLoad, setCompleteLoad } = useGlobalContext();

  const [textOpen, setTextOpen] = useState(false);
  const [textOpen1, setTextOpen1] = useState(false);
  const [textOpen2, setTextOpen2] = useState(false);
  const [textOpen3, setTextOpen3] = useState(false);
  const [textOpen4, setTextOpen4] = useState(false);

  const [mobileText, setMobileText] = useState(false);
  const [mobileText1, setMobileText1] = useState(false);
  const [mobileText2, setMobileText2] = useState(false);
  const [mobileText3, setMobileText3] = useState(false);

  const [img1, setImage1] = useState<boolean>(false);
  const [img2, setImage2] = useState<boolean>(false);
  const [img3, setImage3] = useState<boolean>(false);
  const [img4, setImage4] = useState<boolean>(false);
  const [img5, setImage5] = useState<boolean>(false);

  const refText = useRef(null);
  const refText1 = useRef(null);
  const refText2 = useRef(null);
  const refText3 = useRef(null);

  useEffect(() => {
    if (img1 && img2 && img3 && img4) {
      setCompleteLoad(true);
    }
  }, [img1, img2, img3, img4]);

  const settings = {
    dots: false,
    infinite: true,
    // speed: 200,
    // autoplaySpeed: 2000,
    // autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true,
    slidesToShow: 1.7,
    slidesToScroll: 1,
  };

  const scrollToRef = (element: any) => {
    setTimeout(() => {
      element.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        },
        200
      );
    });
  };

  const data = [
    {
      project: "Neubau einer modernen Reihenhausanlage",
      description: `Um ein Gefühl für die Lage, die hochwertige Bauweise und auch die entstehenden Innenräume vermitteln zu können, haben wir für den mit dem Vertrieb beauftragten Makler Außen- sowie Innenansichten der Immobilien erstellt. Potentielle Kunden müssen sich somit nicht nur auf ihre Fantasie verlassen, sondern wissen genau, was sie erwartet.`,
      company: "Hansa Real Estate GmbH",
      footerCompany: "Neubau einer modernen Reihenhausanlage      ",
      footerEm: "Nach KFW-40",
    },
    {
      project: "Revitalisierung Stadtwohnung",
      description: `Visualisierung einer hochwertigen Sanierung und Darstellung des individuellen Interior Design Konzepts in der Planungsphase des Projektes.      `,
      company: "Sanierung eines Bungalows",
      footerCompany: "Revitalisierung Stadtwohnung",
      footerEm: "Kunde: Agostina Britos",
    },

    {
      project: "Sanierung eines Bungalows",
      description: `Repräsentative Innenraumvisualisierung, in der wir den Designvorschlag der beauftragten Innenarchitektin zur Revitalisierung einer zentral gelegenen Stadtwohnung zeigen.      `,
      company: "Revitalisierung Stadtwohnung",
      footerCompany: "Sanierung eines Bungalows",
      footerEm: "Kunde: Agostina Britos      ",
    },

    {
      project: "Bar rique",
      description: `Die Bar Rique Bremen hat uns mit der Visualisierung des Gastraumkonzepts für eine hochwertige Gastronomie mit Schwerpunkt Wein im prestigeträchtigen Europhafenkopf beauftragt.
      Neben Layout und Raumaufteilung haben wir konkrete Werkstoffe und Materialien der Interieus gezeigt. Die Visualisierungen unterstützen Bauherren und Innenarchitekten in Diskussion und Weiterentwicklung des Designkonzepts.`,
      company: "Bar Rique Winehouse GmbH & Co. KG",
      footerCompany: "Bar rique",
    },
    {
      project: "Neubau-Projekt in Übersee",
      description: `Im idyllischen Übersee am Chiemsee soll direkt an der
      Moosbach gelegen ein exklusiver Neubau mit einem Mix aus
      Gewerbe und Wohnen entstehen. Die erstellten
      Visualisierungen geben auch ortsfremden Investoren und
      Interessenten die Chance, sich neben der geplanten
      Bauweise auch von der hervorragenden Lage einen
      realistischen Eindruck zu verschaffen.`,
      company: "Jota Gamma Projekte GmbH",
      footerCompany: "Neubau-Projekt in Übersee",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div className="hero-section">
        <Slider {...settings}>
          <div className="">
            <div className="slider-flex">
              <div
                data-open={textOpen ? true : false}
                className={
                  textOpen
                    ? "slider-flex-items slider-flex-items-change1"
                    : "slider-flex-items"
                }>
                <div className="text-style">
                  <div className="heading-text">
                    <h1>{data[0].project}</h1>
                  </div>
                  <div className="sub-text">
                    <h3>PROJEKT INFO</h3>
                    <p>{data[0].description}</p>
                  </div>
                  <div className="last-text">
                    <h2>KUNDE</h2>
                    <div className="text-alignment">
                      <span>{data[0].company}</span>
                      <NavLink to="/portfolio">
                        <span>+ unser Portfolio</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  textOpen
                    ? "slider-flex-items slider-flex-items-change2"
                    : "slider-flex-items"
                }
                onClick={() => setTextOpen(!textOpen)}>
                <img
                  src={HomeBanner}
                  alt="HomeBanner"
                  onLoad={() => {
                    setImage1(true);
                  }}
                />
              </div>
            </div>
            <div className="slider-footer-content-alignment12">
              <div className="slider-footer-content-alignment">
                <div className="container-lg">
                  <h2>{data[0].footerCompany}</h2>
               
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="slider-flex">
              <div
                data-open={textOpen1 ? true : false}
                className={
                  textOpen1
                    ? "slider-flex-items slider-flex-items-change1"
                    : "slider-flex-items"
                }>
                <div className="text-style">
                  <div className="heading-text">
                    <h1>{data[1].project}</h1>
                  </div>
                  <div className="sub-text">
                    <h3>PROJEKT INFO</h3>
                    <p>{data[1].description}</p>
                  </div>
                  <div className="last-text">
                    <h2>KUNDE</h2>
                    <div className="text-alignment">
                      <span>Agostina Britos</span>
                      <NavLink to="/portfolio">
                        <span>+ unser Portfolio</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  textOpen1
                    ? "slider-flex-items slider-flex-items-change2"
                    : "slider-flex-items"
                }
                onClick={() => setTextOpen1(!textOpen1)}>
                <img
                  src={HomeBanner1}
                  alt="HomeBanner1"
                  onLoad={() => {
                    setImage2(true);
                  }}
                />
              </div>
            </div>
            <div className="slider-footer-content-alignment12">
              <div className="slider-footer-content-alignment">
                <div className="container-lg">
                  <h2>{data[1].footerCompany}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="slider-flex">
              <div
                data-open={textOpen4 ? true : false}
                className={
                  textOpen4
                    ? "slider-flex-items slider-flex-items-change1"
                    : "slider-flex-items"
                }>
                <div className="text-style">
                  <div className="heading-text">
                    <h1>{data[2].project}</h1>
                  </div>
                  <div className="sub-text">
                    <h3>PROJEKT INFO</h3>
                    <p>{data[2].description}</p>
                  </div>
                  {/* <div className="last-text">
                  <h2>{data[2].footerEm}</h2>
                  </div> */}

                  <div className="last-text">
                    <h2>KUNDE</h2>
                    <div className="text-alignment">
                      <span> Agostina Britos</span>
                      <NavLink to="/portfolio">
                        <span>+ unser Portfolio</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  textOpen4
                    ? "slider-flex-items slider-flex-items-change2"
                    : "slider-flex-items"
                }
                onClick={() => setTextOpen4(!textOpen4)}>
                <img
                  src={HomeBanner5}
                  alt="HomeBanner5"
                  onLoad={() => {
                    setImage5(true);
                  }}
                />
              </div>
            </div>
            <div className="slider-footer-content-alignment12">
              <div className="slider-footer-content-alignment">
                <div className="container-lg">
                  <h2>{data[2].footerCompany}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="slider-flex">
              <div
                data-open={textOpen2 ? true : false}
                className={
                  textOpen2
                    ? "slider-flex-items slider-flex-items-change1"
                    : "slider-flex-items"
                }>
                <div className="text-style">
                  <div className="heading-text">
                    <h1>{data[3].project}</h1>
                  </div>
                  <div className="sub-text">
                    <h3>PROJEKT INFO</h3>
                    <p>{data[3].description}</p>
                  </div>
                  <div className="last-text">
                    <h2>KUNDE</h2>
                    <div className="text-alignment">
                      <span>{data[3].company}</span>
                      <NavLink to="/portfolio">
                        <span>+ unser Portfolio</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  textOpen2
                    ? "slider-flex-items slider-flex-items-change2"
                    : "slider-flex-items"
                }
                onClick={() => setTextOpen2(!textOpen2)}>
                <img
                  src={HomeBanner3}
                  alt="HomeBanner3"
                  onLoad={() => {
                    setImage3(true);
                  }}
                />
              </div>
            </div>
            <div className="slider-footer-content-alignment12">
              <div className="slider-footer-content-alignment">
                <div className="container-lg">
                  <h2>{data[3].footerCompany}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="slider-flex">
              <div
                data-open={textOpen3 ? true : false}
                className={
                  textOpen3
                    ? "slider-flex-items slider-flex-items-change2"
                    : "slider-flex-items"
                }>
                <div className="text-style">
                  <div className="heading-text">
                    <h1>{data[4].project}</h1>
                  </div>
                  <div className="sub-text">
                    <h3>PROJEKT INFO</h3>
                    <p>{data[4].description}</p>
                  </div>
                  <div className="last-text">
                    <h2>KUNDE</h2>
                    <div className="text-alignment">
                      <span>{data[4].company}</span>
                      <NavLink to="/portfolio">
                        <span>+ unser Portfolio</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  textOpen3
                    ? "slider-flex-items slider-flex-items-change2"
                    : "slider-flex-items"
                }
                onClick={() => setTextOpen3(!textOpen3)}>
                <img
                  src={HomeBanner4}
                  alt="HomeBanner"
                  onLoad={() => {
                    setImage4(true);
                  }}
                />
              </div>
            </div>
            <div className="slider-footer-content-alignment12">
              <div className="slider-footer-content-alignment">
                <div className="container-lg">
                  <h2>{data[4].footerCompany}</h2>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>

      <div className="mobile-view-home-section-show">
        <div className="image-text-show-alignmen">
          <div
            className="image-style"
            onClick={() => {
              scrollToRef(refText.current);
              setMobileText(!mobileText);
            }}>
            <div
              data-open={mobileText ? true : false}
              className="overlay-image">
              <p className="overlay-text">Details anzeigen</p>
            </div>
            <img src={MobileBanner} alt="MobileBanner" className="img-1" />
          </div>
          <div
            className={
              mobileText
                ? "mobile-view-hero mobile-view-hero-show"
                : "mobile-view-hero-hidden"
            }>
            <div ref={refText} className="mobile-view-hero-alignment">
              <h2>{data[0].project}</h2>
              <p>{data[0].description}</p>
              <div className="two-text-alignment">
                <span>{data[0].company}</span>
                <NavLink to="/portfolio">
                  <span>+ unser Portfolio</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="image-text-show-alignmen">
          <div
            className="image-style"
            onClick={() => {
              scrollToRef(refText1.current);
              setMobileText1(!mobileText1);
            }}>
            <div
              data-open={mobileText1 ? true : false}
              className="overlay-image">
              <p className="overlay-text">Details anzeigen</p>
            </div>
            <img src={MobileBanner1} alt="MobileBanner1" className="img-2" />
          </div>
          <div
            className={
              mobileText1
                ? "mobile-view-hero mobile-view-hero-show"
                : "mobile-view-hero-hidden"
            }>
            <div ref={refText1} className="mobile-view-hero-alignment">
              <h2>{data[1].project}</h2>
              <p>{data[1].description}</p>
              <div className="two-text-alignment">
                <span>{data[1].company}</span>
                <NavLink to="/portfolio">
                  <span>+ unser Portfolio</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="image-text-show-alignmen">
          <div
            className="image-style"
            onClick={() => {
              scrollToRef(refText2.current);
              setMobileText2(!mobileText2);
            }}>
            <div
              data-open={mobileText2 ? true : false}
              className="overlay-image">
              <p className="overlay-text">Details anzeigen</p>
            </div>
            <img src={MobileBanner2} alt="MobileBanner2" className="img-1" />
          </div>
          <div
            className={
              mobileText2
                ? "mobile-view-hero mobile-view-hero-show"
                : "mobile-view-hero-hidden"
            }>
            <div ref={refText2} className="mobile-view-hero-alignment">
              <h2>{data[2].project}</h2>
              <p>{data[2].description}</p>
              <div className="two-text-alignment">
                <span>{data[2].company}</span>
                <NavLink to="/portfolio">
                  <span>+ unser Portfolio</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="image-text-show-alignmen">
          <div
            className="image-style"
            onClick={() => {
              scrollToRef(refText3.current);
              setMobileText3(!mobileText3);
            }}>
            <div
              data-open={mobileText3 ? true : false}
              className="overlay-image">
              <p className="overlay-text">Details anzeigen</p>
            </div>
            <img src={MobileBanner3} alt="MobileBanner3" className="img-2" />
          </div>
          <div
            className={
              mobileText3
                ? "mobile-view-hero mobile-view-hero-show"
                : "mobile-view-hero-hidden"
            }>
            <div ref={refText3} className="mobile-view-hero-alignment">
              <h2>{data[3].project}</h2>
              <p>{data[3].description}</p>
              <div className="two-text-alignment">
                <span>{data[3].company}</span>
                <NavLink to="/portfolio">
                  <span>+ unser Portfolio</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
