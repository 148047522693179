import React, { useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import Career from "./career";
import Home from "./home";
import Kontakt from "./kontakt";
import KontaktForm from "./kontaktForm";
import Portfolio from "./portfolio";
import Works from "./portfolio/works";
import Service from "./service";
import Bewerbung from "./bewerbung";
import Loading from "../assets/icons/loading.svg";
import { useGlobalContext } from "../context";
import { motion } from "framer-motion";
import Impressum from "./impressum";
import Datenschutz from "./datenschutz";
import CookieForm from "../components/Cookie/CookieForm";
import Landscape from "../assets/lottie/landscape.json";
import Lottie from "react-lottie-player";

export default function Routes() {
  return (
    <>
      <BrowserRouter>
        <RouteWrapper
          exact={true}
          path="/"
          component={Home}
          layout={DefaultLayout}
        />
        <RouteWrapper
          exact={true}
          path="/service"
          component={Service}
          layout={DefaultLayoutMain}
        />
        <RouteWrapper
          exact={true}
          path="/portfolio"
          component={Portfolio}
          layout={DefaultLayoutMain}
        />
        <RouteWrapper
          exact={true}
          path="/works"
          component={Works}
          layout={DefaultLayoutMain}
        />
        <RouteWrapper
          exact={true}
          path="/bewerbung"
          component={Bewerbung}
          layout={DefaultLayoutMain}
        />
        <RouteWrapper
          exact={true}
          path="/career"
          component={Career}
          layout={DefaultLayoutMain}
        />
        <RouteWrapper
          exact={true}
          path="/kontakt"
          component={Kontakt}
          layout={DefaultLayoutMain}
        />
        <RouteWrapper
          exact={true}
          path="/kontakt-form"
          component={KontaktForm}
          layout={DefaultLayoutMain}
        />
        <RouteWrapper
          exact={true}
          path="/impressum"
          component={Impressum}
          layout={DefaultLayoutMain}
        />{" "}
        <RouteWrapper
          exact={true}
          path="/datenschutz"
          component={Datenschutz}
          layout={DefaultLayoutMain}
        />
      </BrowserRouter>
    </>
  );
}

function RouteWrapper({
  component: Component,
  layout: Layout,
  auth,
  ...rest
}: any) {
  return (
    <Route
      {...rest}
      render={(props: any) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

const DefaultLayout = ({ children, match }: any) => {
  const { completeLoad } = useGlobalContext();
  const [show, setShow] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);

  return (
    <>
      <motion.div
        initial={{
          opacity: 1,
          display: "flex",
        }}
        animate={{
          opacity: completeLoad ? 0 : 1,
          display: completeLoad ? "none" : "flex",
          transition: {
            duration: 0.5,
            delay: 1,
            ease: "easeIn",
          },
        }}
        className="container-loading">
        <img src={Loading} alt="loading" className="loading-img" />
      </motion.div>
      <div className="to-portrait">
        <Lottie
          animationData={Landscape}
          loop
          play
          style={{ width: 150, height: 150 }}
        />
        <p className="font-bold text-sm text-white">
          Bitte drehen Sie das Gerät
        </p>
      </div>
      <CookieForm
        show={show}
        setShow={setShow}
        cancel={cancel}
        setCancel={setCancel}
      />
      <Header />
      {children}
    </>
  );
};
const DefaultLayoutMain = ({ children, match }: any) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);
