import React from "react";
import "./teamSection.scss";
import TeamImage from "../../../assets/images/team.webp";
import TeamImage1 from "../../../assets/images/team1.webp";
import Web from "../../../assets/icons/web.png";
import Email from "../../../assets/icons/mail.png";
import Location from "../../../assets/icons/location.png";

export default function TeamSection() {
  return (
    <div>
      <div className="team-section-all-content-alignment">
        <div className="container-md">
          <div className="page-title">
            <h2
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
              Team
            </h2>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
              {" "}
              Aus Bremen für die ganze Welt
            </p>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100"
            >
              Der Renderkings Hauptsitz befindet sich zentral im schönen Bremen.
              Von hier aus bearbeiten wir sowohl regionale als auch
              überregionale Projekte im Architektur- und Immobilienbereich, um
              unsere Kunden mit den passenden Visualisierungen zu unterstützen.
            </p>
            <div className="line-center-alignment">
              <div className="line"></div>
            </div>
          </div>
          <div className="team-section-content-alignment">
            <div className="team-image">
              <div className="team-grid">
                <div className="team-grid-items">
                  <img src={TeamImage} alt="TeamImage" />
                  <p className="name-team">Hauke Molter</p>
                  <p>
                    <div className="container-link">
                      <img src={Email} alt="" />
                      <a href="mailto:hm@renderkings.de">hm@renderkings.de</a>
                    </div>
                  </p>
                </div>
                <div className="team-grid-items">
                  <img src={TeamImage1} alt="TeamImage1" />
                  <p className="name-team">Jasper Schouw</p>
                  <p>
                    <a href="mailto:js@renderkings.de">
                      <div className="container-link">
                        <img src={Email} alt="" />
                        js@renderkings.de
                      </div>
                    </a>
                  </p>
                </div>
                <div className="general-container">
                  <div className="team-grid-items">
                    <p className="title">Renderkings UG</p>
                    <p>
                      <a href="mailto:js@renderkings.de">
                        <div className="container-link">
                          <img src={Email} alt="" />
                          js@renderkings.de
                        </div>
                      </a>
                    </p>
                    <p>
                      <div
                        onClick={() => {
                          window.open(
                            "https://maps.google.com?q=" +
                              53.07200329013258 +
                              "," +
                              8.868200357670398
                          );
                        }}
                        className="container-link location"
                      >
                        <img
                          src={Location}
                          alt=""
                          style={{
                            height: "1.5rem",
                          }}
                        />
                        Insterburger Straße 5,
                        <br /> 28207 Bremen
                      </div>
                    </p>
                    <p>
                      <a href="http://www.renderkings.de/">
                        <div className="container-link">
                          <img src={Web} alt="" />
                          renderkings.de
                        </div>
                      </a>
                    </p>
                    <p>
                      <a href="tel:0421 64 85 29 66">
                        <div className="container-link">
                          <svg
                            style={{ width: "20px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                          </svg>
                          0421 64 85 29 66
                        </div>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
