import React, { useEffect, useState } from "react";
import "./header.scss";
import Logo from "../../assets/logo/logo.svg";
import MobileMenu from "../../assets/icons/mobile-menu.svg";
import { NavLink } from "react-router-dom";
import disableScroll from "disable-scroll";

export default function Header() {
  const [headerOpen, setHeaderOpen] = useState(false);

  useEffect(() => {
    if (headerOpen) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
  }, [headerOpen]);

  return (
    <>
      <div className="header-style">
        <div className="container-lg">
          <div className="header-alignment">
            <div className="logo">
              <NavLink to="/">
                <img src={Logo} />
              </NavLink>
            </div>
            <div className="menu space-x-8">
              <NavLink title="Renderkings" to="/service">
                Renderkings
              </NavLink>
              <NavLink title="Portfolio" to="/portfolio">
                Portfolio
              </NavLink>
              <NavLink title="Ihr Angebot" to="/kontakt">
                Ihr Angebot
              </NavLink>
              <NavLink title="Karriere" to="/career">
                Karriere
              </NavLink>
              <NavLink title="Kontakt" to="/kontakt-form">
                Kontakt
              </NavLink>
            </div>
            <div
              data-open={headerOpen ? true : false}
              className="mobile-menu"
              onClick={() => setHeaderOpen(!headerOpen)}>
              <div
                data-open={headerOpen ? true : false}
                className="line line-top"></div>
              <div
                data-open={headerOpen ? true : false}
                className="line line-mid"></div>
              <div
                data-open={headerOpen ? true : false}
                className="line line-diag"></div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          headerOpen
            ? "mobile-header mobile-header-show"
            : "mobile-header mobile-header-hidden"
        }>
        <div className="mobile-nav-body">
          <div className="menu">
            <NavLink onClick={() => setHeaderOpen(false)} to="/service">
              Renderkings
            </NavLink>
            <NavLink onClick={() => setHeaderOpen(false)} to="/portfolio">
              Portfolio
            </NavLink>
            <NavLink to="/kontakt" className="active-new-class">
              Ihr Angebot
            </NavLink>
            <NavLink onClick={() => setHeaderOpen(false)} to="/career">
              Karriere
            </NavLink>
            <NavLink onClick={() => setHeaderOpen(false)} to="/kontakt-form">
              Kontakt
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
