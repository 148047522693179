import "./App.css";
import "./styles/mixins/global.scss";
import Routes from "./routes";
import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MyGlobalContext } from "./context";

function App() {
  const [btnHidden, setBtnHidden] = useState<boolean>(false);
  const [company, setCompany] = useState<string>("");
  const [job, setJob] = useState<string>("");
  const [place, setPlace] = useState<string>("");
  const [jobType, setJobType] = useState<string>("");
  const [date, setSelectDate] = useState<string>("");
  const [selectJob, setSelectJob] = useState<string>("");
  const [completeLoad, setCompleteLoad] = useState<boolean>(false);

  return (
    <MyGlobalContext.Provider
      value={{
        job,
        setJob,
        place,
        setPlace,
        jobType,
        setJobType,
        date,
        setSelectDate,
        selectJob,
        setSelectJob,
        completeLoad,
        setCompleteLoad,
      }}>
      <Routes />
    </MyGlobalContext.Provider>
  );
}

export default App;
