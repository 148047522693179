import React, { useEffect } from "react";
import "./service.scss";
import ServiceImage from "../../assets/images/service.png";
import TeamSection from "./teamSection";
import Partner from "./partnerSection";
export default function Service() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="service-section-hero-section-alignment">
        <div className="container-md">
          <div className="page-title">
            <h1
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Bilder sagen mehr als Worte - auch im Architektur- und
              <br />
              Immobilienbereich.{" "}
            </h1>
            <h2
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Willkommen bei Renderkings
            </h2>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Im Immobilienmarketing kommt es besonders darauf an, den
              Interessenten schon von Beginn an zu zeigen, wie ihre neue
              Immobilie einmal aussehen könnte - denn visuelle Reize sind in der
              Regel eindrucksvoller als sachliche Beschreibungen. Renderkings
              bietet Ihnen daher professionelle Architekturvisualisierungen,
              Renderings und detailgetreues Digital Staging für Wohn- und
              Gewerbeimmobilien, auch wenn diese sich noch im Bau befinden. So
              können sich potentielle Interessenten ein Bild vom Objekt machen,
              noch bevor der erste Spatenstich getan wurde.
            </p>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Wir verwandeln Ihre Visionen in echte Bilder - damit Ihre Kunden
              das Potential Ihres Gebäudes erkennen, noch bevor es
              fertiggestellt ist.
            </p>
          </div>
        </div>
      </div>
      <div className="service-image-full-section">
        <div className="container-lg-2">
          <img
            className="animate__animated animate__slideInUp wow"
            data-wow-offset="100"
            src={ServiceImage}
            alt="ServiceImage"
          />
        </div>
      </div>
      <TeamSection />
      <Partner />
    </div>
  );
}
