import { createContext, useContext } from "react";
export type GlobalContent = {
  job: string;
  setJob: (c: string) => void;
  place: string;
  setPlace: (c: string) => void;
  jobType: string;
  setJobType: (c: string) => void;
  date: string;
  setSelectDate: (c: string) => void;
  selectJob: string;
  setSelectJob: (c: string) => void;
  completeLoad: boolean;
  setCompleteLoad: (c: boolean) => void;
};
export const MyGlobalContext = createContext<GlobalContent>({
  job: "",
  setJob: () => {},
  place: "",
  setPlace: () => {},
  jobType: "",
  setJobType: () => {},
  date: "",
  setSelectDate: () => {},
  selectJob: "",
  setSelectJob: () => {},
  completeLoad: false,
  setCompleteLoad: () => {},
});

export const useGlobalContext = () => useContext(MyGlobalContext);
