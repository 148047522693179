import React, { useEffect } from "react";
import "./impressum.scss";

export default function Impressum() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="container-impressum">
      <p className="title-impressum">
        <strong>Impressum</strong>
      </p>
      <p>
        Renderkings UG (haftungsbeschränkt) Insterburger Straße 5 <br />
        28207 Bremen
      </p>
      <p>
        Renderkings UG Insterburger Stra&szlig;e 5 <br /> 28207 Bremen
      </p>
      <p>
        Telefon: 0421 64 85 29 66 <br /> E-Mail: js@renderkings.de <br />
        <br /> USt-ID: DE355902467
        <br /> Steuernummer: 60 126 19489
      </p>
      <p>
        <strong>Vertreten durch: </strong>
      </p>
      <p>Hauke Molter Jasper Schouw</p>
      <p>
        <strong>Registereintrag: </strong>
      </p>
      <p>
        Eingetragen im Handelsregister. Registergericht: Amtsgericht Bremen
        Registernummer: HRB 38450 HB
      </p>
      <p className="title-impressum-2">
        <strong>Hinweis gemä&szlig; Online-Streitbeilegungs-Verordnung </strong>
      </p>
      <p>
        Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz
        der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen, die für
        die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein
        Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist
        die Europäische Kommission zuständig. Die Europäische
        Online-Streitbeilegungs-Plattform ist hier zu finden:
        http://ec.europa.eu/odr. Unsere E-Mail lautet: js@renderkings.de
      </p>
      <p>
        Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am
        Streitbeilegungsverfahren im Rahmen der Europäischen
        Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur
        Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.
      </p>
      <p className="title-impressum-2">
        <strong>
          Hinweis gemä&szlig; Verbraucherstreitbeilegungsgesetz (VSBG){" "}
        </strong>
      </p>
      <p>
        Wir sind nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor
        einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <p className="title-impressum-2">
        <strong>Disclaimer &ndash; rechtliche Hinweise </strong>
      </p>
      <ul>
        <li>
          1 Warnhinweis zu Inhalten
          <br /> Die kostenlosen und frei zugänglichen Inhalte dieser Webseite
          wurden mit grö&szlig;tmöglicher Sorgfalt erstellt. Der Anbieter dieser
          Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und
          Aktualität der bereitgestellten kostenlosen und frei zugänglichen
          journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnete
          Beiträge geben die Meinung des jeweiligen Autors und nicht immer die
          Meinung des Anbieters wieder. Allein durch den Aufruf der kostenlosen
          und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis
          zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am
          Rechtsbindungswillen des Anbieters.
        </li>
        <li>
          2 Externe Links
          <br /> Diese Website enthält Verknüpfungen zu Websites Dritter
          ("externe Links"). Diese Websites unterliegen der Haftung der
          jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung
          der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
          Rechtsverstö&szlig;e bestehen. Zu dem Zeitpunkt waren keine
          Rechtsverstö&szlig;e ersichtlich. Der Anbieter hat keinerlei Einfluss
          auf die aktuelle und zukünftige Gestaltung
        </li>
      </ul>
      <p>
        und auf die Inhalte der verknüpften Seiten. Das Setzen von externen
        Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder
        Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der
        externen Links ist für den Anbieter ohne konkrete Hinweise auf
        Rechtsverstö&szlig;e nicht zumutbar. Bei Kenntnis von
        Rechtsverstö&szlig;en werden jedoch derartige externe Links unverzüglich
        gelöscht.
      </p>
      <ul>
        <li>
          3 Urheber- und Leistungsschutzrechte
          <br /> Die auf dieser Website veröffentlichten Inhalte unterliegen dem
          deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
          Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf
          der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
          Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
          Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
          von Inhalten in Datenbanken oder anderen elektronischen Medien und
          Systemen. Inhalte und Rechte Dritter sind dabei als solche
          gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
          einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
          strafbar. Lediglich die Herstellung von Kopien und Downloads für den
          persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
        </li>
      </ul>
      <p>
        Die Darstellung dieser Website in fremden Frames ist nur mit
        schriftlicher Erlaubnis zulässig.
      </p>
      <ul>
        <li>
          4 Besondere Nutzungsbedingungen
          <br /> Soweit besondere Bedingungen für einzelne Nutzungen dieser
          Website von den vorgenannten Paragraphen abweichen, wird an
          entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle
          gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
        </li>
      </ul>
    </div>
  );
}
