import React, { useState, useEffect } from "react";
import "./portfolioDetails.scss";
import RH1 from "../../../assets/images/a1.jpeg";
import RH2 from "../../../assets/images/a2.jpeg";
import RH3 from "../../../assets/images/a3.jpeg";
import RH4 from "../../../assets/images/a4.jpeg";
import RH5 from "../../../assets/images/a5.jpeg";
import RH6 from "../../../assets/images/a6.jpeg";

import RB1 from "../../../assets/images/b1.jpeg";
import RB2 from "../../../assets/images/b2.jpeg";
import RB3 from "../../../assets/images/b3.jpeg";

import RC1 from "../../../assets/images/c1.jpeg";
import RC2 from "../../../assets/images/c2.jpeg";
import RC3 from "../../../assets/images/c3.jpeg";

import ME1 from "../../../assets/images/new123.jpg";
import ME2 from "../../../assets/images/m2.png";
import ME3 from "../../../assets/images/m3.jpg";
import ME4 from "../../../assets/images/m4.jpg";
import ME5 from "../../../assets/images/m5.jpg";

import RE1 from "../../../assets/images/ride_e_1.webp";
import RI1 from "../../../assets/images/ride_i_1.webp";
import RI2 from "../../../assets/images/ride_i_2.webp";
import RI3 from "../../../assets/images/ride_i_3.webp";
import RI4 from "../../../assets/images/ride_i_4.webp";

import DE1 from "../../../assets/images/dorsftext0.webp";
import DE2 from "../../../assets/images/DORFST EXT-2.webp";
import DE3 from "../../../assets/images/DORFST EXT.webp";
import DE4 from "../../../assets/images/DROFST EXT 02.webp";
import DE5 from "../../../assets/images/DROFST EXT 03.webp";

import SE1 from "../../../assets/images/Scheebel EXT 01.webp";
import SE2 from "../../../assets/images/Scheebel EXT 03.webp";

import GI1 from "../../../assets/images/Küche Final.webp";
import Corousel from "../../../components/carousel";

export default function PortfolioDetails() {
  const [menu, setMenu] = useState("All");
  const [open, setOpen] = useState<boolean>(false);

  const [[page, direction], setPage] = useState([0, 0]);

  const arrayFilter = (term: string) => {
    let aux: any = [];

    if (term === "All") {
      aux = [
        ...data[0].int,
        ...data[1].int,
        ...data[2].int,
        ...data[3].ext,
        ...data[3].int,
        ...data[4].int,
        ...data[5].ext,
        ...data[6].ext,
      ];
    } else if (term === "Exte") {
      aux = [...data[3].ext, ...data[5].ext, ...data[6].ext];
    } else if (term === "Inte") {
      aux = [...data[0].int, ...data[1].int,...data[2].int,...data[3].int,...data[4].int];
    }

    return aux;
  };

  const [images, setImages] = useState(arrayFilter("All"));

  useEffect(() => {
    if (menu === "All") {
      setImages(arrayFilter("All"));
    } else if (menu === "Exte") {
      setImages(arrayFilter("Exte"));
    } else if (menu === "Inte") {
      setImages(arrayFilter("Inte"));
    }
  }, [menu]);

  return (
    <div>
      <Corousel
        open={open}
        setOpen={setOpen}
        images={images}
        page={page}
        direction={direction}
        setPage={setPage}
      />
      <div className="portfolio-details-all-content-alignment">
        <div className="container-lg-6">
          <div className="">
            <div className="tab">
              <a
                className={`tab-portfolio ${
                  menu == "All" ? "port-active" : ""
                }`}
                onClick={() => {
                  setMenu("All");
                }}>
                Alle
              </a>
              <a
                className={`tab-portfolio ${
                  menu == "Exte" ? "port-active" : ""
                }`}
                onClick={() => {
                  setMenu("Exte");
                }}>
                Außenansichten
              </a>
              <a
                className={`tab-portfolio ${
                  menu == "Inte" ? "port-active" : ""
                }`}
                onClick={() => {
                  setMenu("Inte");
                }}>
                Innenansichten
              </a>
            </div>
          </div>
          <div className="page-title">
            <h1
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Portfolio von Renderkings
            </h1>
            <p
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="100">
              Hier finden Sie eine Auswahl unserer bisherigen Projekte und
              können sich einen ersten Eindruck davon verschaffen, wie wir
              arbeiten und was unsere Ästhetik ausmacht.
            </p>
            <div className="line-center-alignment">
              <div className="line"></div>
            </div>
          </div>

          <div className="grid">
            {images.map((image: any, index: number) => {
              return (
                <div key={index} className="grid-items">
                  <div className="card-image">
                    <img
                      src={image.img}
                      alt="CardImage16"
                      onClick={() => {
                        setPage([index, 0]);
                        setOpen(true);
                      }}
                    />
                  </div>
                  <div className="card-details">
                    <p>{image.project}</p>
                    <span>{image.client}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const data = [
  {
    ext: [],
    int: [
      {
        id: 0,
        img: RH1,
        project: "Bar Rique",
        client: "Bar Rique Winehouse GmbH & Co. KG",
        allImg:[RH1,RH2,RH3,RH4,RH5,RH6]
      },
    ],
  },
  {
    ext: [],
    int: [
      {
        id: 0,
        img: RB1,
        project: "Revitalisierung Stadtwohnung",
        client: "Agostina Britos",
        allImg:[RB1,RB2,RB3]
      },
    ],
  },
  {
    ext: [],
    int: [
      {
        id: 0,
        img: RC1,
        project: " Sanierung eines Bungalows",
        client: " Agostina Britos",
        allImg:[RC1,RC2,RC3]
      },
    ],
  },
  {
    ext: [
      {
        id: 0,
        img: ME1,
        project: "Neubau einer modernen Reihenhausanlage",
        client: "Hansa Real Estate GmbH",
        allImg:[ME1 , ME2,ME3,ME4,ME5]
      },
    ],
    int: [
      {
        id: 0,
        img: RI1,
        project: "Neubau KFW-40 Doppelhäuser in Riede",
        client: "Jota Gamma Projekte GmbH",
        allImg:[RI1,RI2,RI3,RI4]
      },
      // {
      //   id: 0,
      //   img: RI2,
      //   project: "Neubau KFW-40 Doppelhäuser in Riede",
      //   client: "Hansa Real Estate GmbH",
      // },
      // {
      //   id: 0,
      //   img: RI3,
      //   project: "Neubau KFW-40 Doppelhäuser in Riede",
      //   client: "Hansa Real Estate GmbH",
      // },
      // {
      //   id: 0,
      //   img: RI4,
      //   project: "Neubau KFW-40 Doppelhäuser in Riede",
      //   client: "Hansa Real Estate GmbH",
      // },
    ],
  },
  {
    ext: [],
    int: [
      {
        id: 1,
        img: GI1,
        project: "Altbau Sanierung nach KFW-55-EE in Bremen",
        client: "Jota Gamma Projekte GmbH",
        allImg:[GI1]
      },
    ],
  },
  {
    ext: [
      {
        id: 2,
        img: DE1,
        project: "Neubau-Projekt in Übersee",
        client: "Jota Gamma Projekte GmbH ",
        allImg:[DE1,DE2,DE3,DE4,DE5]
      },
      // {
      //   id: 2,
      //   img: DE2,
      //   project: "Neubau-Projekt in Übersee",
      //   client: "Jota Gamma Projekte GmbH ",
      // },
      // {
      //   id: 2,
      //   img: DE3,
      //   project: "Neubau-Projekt in Übersee",
      //   client: "Jota Gamma Projekte GmbH ",
      // },
      // {
      //   id: 2,
      //   img: DE4,
      //   project: "Neubau-Projekt in Übersee",
      //   client: "Jota Gamma Projekte GmbH ",
      // },
      // {
      //   id: 2,
      //   img: DE5,
      //   project: "Neubau-Projekt in Übersee",
      //   client: "Jota Gamma Projekte GmbH ",
      // },
    ],
    int: [],
  },
  {
    ext: [
      {
        id: 3,
        img: SE1,
        project: "Neubau Mehrfamilienhaus in Scheeßel",
        client: "Öngel Bauplanung GmbH",
        allImg:[SE1,SE2]
      },
      // {
      //   id: 3,
      //   img: SE2,
      //   project: "Neubau Mehrfamilienhaus in Scheeßel",
      //   client: "Öngel Bauplanung GmbH",
      // },
    ],
    int: [],
  },
];
