import React, { useEffect } from "react";
import "./portfolio.scss";
import PortfolioImage from "../../assets/images/portfolio.webp";
import PortfolioDetails from "./portfolioDetails";
export default function Portfolio() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="portfolio-hero-banner-alignment">
        <div className="container-lg-5">
          <img src={PortfolioImage} alt="PortfolioImage" />
        </div>
      </div>
      <PortfolioDetails />
    </div>
  );
}
