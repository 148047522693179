import React from "react";

export default function Step2(props: any) {
  const { formData, handleChange, errors, setErrors, step, setStep } = props;

  const validateStep2 = () => {
    let formIsValid = true;
    let errors: any = {};
    if (formData && !formData.Enter?.trim()) {
      formIsValid = false;
      errors["Enter"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Payment?.trim()) {
      formIsValid = false;
      errors["Payment"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Notice?.trim()) {
      formIsValid = false;
      errors["Notice"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    setErrors(errors);
    return formIsValid;
  };
  return (
    <div>
      <p className="text-3xl font-bold">Sonstiges</p>
      <div className="grid md:grid-cols-2 md:mt-10 lg:gap-x-28 mt-4">
        <div>
          <div className="input">
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="date"
                  placeholder=" "
                  id="Enter"
                  name="Enter"
                  value={formData.Enter && formData.Enter}
                  onChange={(e) => handleChange(e)}
                />
                <label>Frühester Eintritt</label>

                <p style={{ fontSize: "10px", color: "red" }}>{errors.Enter}</p>
              </div>
            </div>
          </div>
          <div className="input">
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="number"
                  placeholder=" "
                  id="Payment"
                  name="Payment"
                  value={formData.Payment && formData.Payment}
                  onChange={(e) => handleChange(e)}
                />
                <label>Gehaltsvorstellung</label>

                <p style={{ fontSize: "10px", color: "red" }}>
                  {errors.Payment}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="input">
            <div className="floating-form">
              <div className="floating-label">
                <input
                  className="floating-input"
                  type="text"
                  placeholder=" "
                  id="Notice"
                  name="Notice"
                  value={formData.Notice && formData.Notice}
                  onChange={(e) => handleChange(e)}
                />
                <label>Wie haben Sie uns gefunden? *</label>

                <p style={{ fontSize: "10px", color: "red" }}>
                  {errors.Notice}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:col-span-2 mt-6">
          <div className="w-full flex justify-between">
            <div
              onClick={() => {
                setStep(step - 1);
              }}
              className="bg-black px-7 md:py-2 py-1.5 text-white hover:cursor-pointer md:min-w-[6.5rem] min-w-[6rem] text-center">
              Zurück
            </div>
            <div
              onClick={() => {
                if (validateStep2()) {
                  setStep(step + 1);
                }
              }}
              className="bg-black px-7 md:py-2 py-1.5 text-white hover:cursor-pointer md:min-w-[6.5rem] min-w-[6rem] text-center">
              Weiter
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
