import React, { useEffect } from "react";
import "./datench.scss";

export default function Datenschutz() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="container-daten">
      <h1>
        <strong>Datenschutzerkl&auml;rung</strong>
      </h1>
      <p>
        Mit dieser Datenschutzerkl&auml;rung m&ouml;chten wir Sie &uuml;ber Art,
        Umfang und Zweck der Verarbeitung von personenbezogenen Daten (im
        Folgenden auch nur als "Daten" bezeichnet) aufkl&auml;ren.
        Personenbezogene Daten sind alle Daten, die einen pers&ouml;nlichen
        Bezug zu Ihnen aufweisen, z. B. Name, Adresse, E-Mail-Adresse oder Ihr
        Nutzerverhalten. Die Datenschutzerkl&auml;rung gilt f&uuml;r alle von
        uns vorgenommene Daten-Verarbeitungsvorg&auml;nge sowohl im Rahmen
        unserer Kernt&auml;tigkeit als auch f&uuml;r die von uns vorgehaltenen
        Online-Medien.
      </p>
      <h3>
        <strong>
          Wer bei uns f&uuml;r die Datenverarbeitung verantwortlich ist
        </strong>
      </h3>
      <p>Verantwortlich f&uuml;r die Datenverarbeitung ist:</p>
      <p>
        Renderkings UG
        <br /> Hauke Molter, Jasper Schouw
        <br /> Insterburger Stra&szlig;e 5<br /> 28207 Bremen
        <br /> Bremen
        <br /> 0421 64 85 29 66
        <br /> js@renderkings.de
        <br /> www.renderkings.de/impressum
      </p>
      <h3>
        <strong>Kontaktdaten unseres Datenschutzbeauftragten</strong>
      </h3>
      <p>
        Unseren Datenschutzbeauftragten k&ouml;nnen Sie per E-Mail unter
        js@renderkings oder unter unserer Postadresse mit dem Zusatz &bdquo;an
        den Datenschutzbeauftragten&ldquo; erreichen.
      </p>
      <h3>
        <strong>
          Verarbeitung Ihrer Daten im Rahmen der Kernt&auml;tigkeit unseres
          Unternehmens
        </strong>
      </h3>
      <p>
        Sofern Sie unser Kunde oder Gesch&auml;ftspartner sind oder sich
        f&uuml;r unsere Leistungen interessieren, richtet sich Art, Umfang und
        Zweck der Verarbeitung Ihrer Daten nach dem zwischen uns bestehenden
        vertraglichen bzw. vorvertraglichen Beziehungen. In diesem Sinne
        geh&ouml;ren zu den von uns verarbeiteten Daten all diejenigen Daten,
        die zum Zwecke der Inanspruchnahme der vertraglichen bzw.
        vorvertraglichen Leistungen durch Sie bereitgestellt werden bzw. wurden
        und die zur Abwicklung Ihrer Anfrage oder des zwischen uns geschlossenen
        Vertrages ben&ouml;tigt werden. Sofern sich aus den weiteren Hinweisen
        dieser Datenschutzerkl&auml;rung nichts anderes ergibt, beschr&auml;nkt
        sich die Verarbeitung Ihrer Daten sowie deren Weitergabe an Dritte auf
        diejenigen Daten, die zur Beantwortung Ihrer Anfragen und/oder zur
        Erf&uuml;llung des zwischen Ihnen und uns geschlossenen Vertrages, zur
        Wahrung unserer Rechte sowie zur Erf&uuml;llung gesetzlicher Pflichten
        erforderlich und zweckm&auml;&szlig;ig sind. Welche Daten hierf&uuml;r
        erforderlich sind, teilen wir Ihnen vor oder im Rahmen der Datenerhebung
        mit. Soweit wir zur Erbringung unserer Leistungen Drittanbieter
        einsetzen, gelten die Datenschutzhinweise der jeweiligen Drittanbieter.
      </p>
      <h3>
        <strong>Betroffene Daten:</strong>
      </h3>
      <ul>
        <li>Bestandsdaten (bspw. Namen, Adressen)</li>
        <li>Zahlungsdaten (bspw. Bankverbindungsdaten, Rechnungen)</li>
        <li>
          Kontakdaten (bspw. E-Mail-Adresse, Telefonnummer, Postanschrift)
        </li>
        <li>Vertragsdaten (bspw. Vertragsgegenstand, Vertragsdauer)</li>
      </ul>
      <p>
        <strong>Betroffene Personen:&nbsp;</strong>Interessenten,
        Gesch&auml;fts- und Vertragspartner
      </p>
      <p>
        <strong>Verarbeitungszweck:&nbsp;</strong>Abwicklung vertraglicher
        Leistungen, Kommunikation sowie Beantwortung von Kontaktanfragen,
        B&uuml;ro und Organisationsverfahren
      </p>
      <p>
        <strong>Rechtsgrundlage:</strong>&nbsp;Vertragserf&uuml;llung und
        vorvertragliche Anfragen, Art. 6 Abs. 1 lit. b DSGVO, rechtliche
        Verpflichtung, Art. 6 Abs. 1 lit. c DSGVO, berechtigtes Interesse, Art.
        6 Abs. 1 lit. f DSGVO
      </p>
      <h3>
        <strong>Ihre Rechte nach der DSGVO</strong>
      </h3>
      <p>
        Nach der DSGVO stehen Ihnen die nachfolgend aufgef&uuml;hrten Rechte zu,
        die Sie jederzeit bei dem in Ziffer 1. dieser Datenschutzerkl&auml;rung
        genannten Verantwortlichen geltend machen k&ouml;nnen:
      </p>
      <ul>
        <li>
          <strong>Recht auf Auskunft:</strong>&nbsp;Sie haben das Recht, von uns
          Auskunft dar&uuml;ber zu verlangen, ob und welche Daten wir von Ihnen
          verarbeiten.
        </li>
        <li>
          <strong>Recht auf Berichtigung:</strong>&nbsp;Sie haben das Recht, die
          Berichtigung unrichtiger oder Vervollst&auml;ndigung
          unvollst&auml;ndiger Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf L&ouml;schung:</strong>&nbsp;Sie haben das Recht,
          die L&ouml;schung Ihrer Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Einschr&auml;nkung:</strong>&nbsp;Sie haben in
          bestimmten F&auml;llen das Recht zu verlangen, dass wir Ihre Daten nur
          noch eingeschr&auml;nkt bearbeiten.
        </li>
        <li>
          <strong>Recht auf Daten&uuml;bertragbarkeit:</strong>&nbsp;Sie haben
          das Recht zu verlangen, dass wir Ihnen oder einem anderen
          Verantwortlichen Ihre Daten in einem strukturierten, g&auml;ngigen und
          maschinenlesebaren Format &uuml;bermitteln.
        </li>
        <li>
          <strong>Beschwerderecht</strong>: Sie haben das Recht, sich bei einer
          Aufsichtsbeh&ouml;rde zu beschweren. Zust&auml;ndig ist die
          Aufsichtsbeh&ouml;rde Ihres &uuml;blichen Aufenthaltsortes, Ihres
          Arbeitsplatzes oder unseres Firmensitzes.
        </li>
      </ul>
      <h3>
        <strong>Widerrufsrecht</strong>
      </h3>
      <p>
        Sie haben das Recht, die von Ihnen erteilte Einwilligung zur
        Datenverarbeitung jederzeit zu widerrufen.
      </p>
      <h3>
        <strong>Widerspruchsrecht</strong>
      </h3>
      <p>
        Sie haben das Recht, jederzeit gegen die Verarbeitung Ihrer Daten, die
        wir auf unser berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO
        st&uuml;tzen, Widerspruch einzulegen. Sofern Sie von Ihrem
        Widerspruchsrecht Gebrauch machen, bitten wir Sie um die Darlegung der
        Gr&uuml;nde. Wir werden Ihre personenbezogenen Daten dann nicht mehr
        verarbeiten, es sei denn, wir k&ouml;nnen Ihnen gegen&uuml;ber
        nachweisen, dass zwingende schutzw&uuml;rdige Gr&uuml;nde an der
        Datenverarbeitung Ihre Interessen und Rechte &uuml;berwiegen.
      </p>
      <h3>
        <strong>
          <u>
            Unabh&auml;ngig vom vorstehend Gesagten, haben Sie das jederzeitige
            Recht, der Verarbeitung Ihrer personenbezogenen Daten f&uuml;r
            Zwecke der Werbung und Datenanalyse zu widersprechen.
          </u>
        </strong>
      </h3>
      <p>
        Ihren Widerspruch richten Sie bitte an die oben angegebene
        Kontaktadresse des Verantwortlichen.
      </p>
      <h3>
        <strong>Wann l&ouml;schen wir Ihre Daten?</strong>
      </h3>
      <p>
        Wir l&ouml;schen Ihre Daten dann, wenn wir diese nicht mehr brauchen
        oder Sie uns dies vorgeben. Das bedeutet, dass - sofern sich aus den
        einzelnen Datenschutzhinweisen dieser Datenschutzerkl&auml;rung nichts
        anderes ergibt - wir Ihre Daten l&ouml;schen,
      </p>
      <ul>
        <li>
          wenn der Zweck der Datenverarbeitung weggefallen ist und damit die
          jeweilige in den einzelnen Datenschutzhinweisen genannte
          Rechtsgrundlage nicht mehr besteht, also bspw.
        </li>
      </ul>
      <ul>
        <ul>
          <li>
            nach Beendigung der zwischen uns bestehenden vertraglichen oder
            mitgliedschaftlichen Beziehungen (Art. 6 Abs. 1 lit. a DSGVO) oder
          </li>
        </ul>
      </ul>
      <ul>
        <ul>
          <li>
            nach Wegfall unseres berechtigten Interesses an der weiteren
            Verarbeitung oder Speicherung Ihrer Daten (Art. 6 Abs. 1 lit. f
            DSGVO),
          </li>
        </ul>
      </ul>
      <ul>
        <li>
          wenn Sie von Ihrem Widerrufsrecht Gebrauch machen und keine
          anderweitige gesetzliche Rechtsgrundlage f&uuml;r die Verarbeitung im
          Sinne von Art. 6 Abs. 1 lit. b-f DSGVO eingreift,
        </li>
        <li>
          wenn Sie vom Ihrem Widerspruchsrecht Gebrauch machen und der
          L&ouml;schung keine zwingenden schutzw&uuml;rdigen Gr&uuml;nde
          entgegenstehen.
        </li>
      </ul>
      <p>
        Sofern wir (bestimmte Teile) Ihre(r) Daten jedoch noch f&uuml;r andere
        Zwecke vorhalten m&uuml;ssen, weil dies etwa steuerliche
        Aufbewahrungsfristen (in der Regel 6 Jahre f&uuml;r
        Gesch&auml;ftskorrespondenz bzw. 10 Jahre f&uuml;r Buchungsbelege) oder
        die Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen aus vertraglichen Beziehungen (bis zu vier Jahren)
        erforderlich machen oder die Daten zum Schutz der Rechte einer anderen
        nat&uuml;rlichen oder juristischen Person gebraucht werden, l&ouml;schen
        wir (den Teil) Ihre(r) Daten erst nach Ablauf dieser Fristen. Bis zum
        Ablauf dieser Fristen beschr&auml;nken wir die Verarbeitung dieser Daten
        jedoch auf diese Zwecke (Erf&uuml;llung der Aufbewahrungspflichten).
      </p>
      <h3>
        <strong>Cookies</strong>
      </h3>
      <p>
        Unsere Internetseite nutzt Cookies. Bei Cookies handelt es sich um
        kleine Textdateien, bestehend aus einer Reihe von Zahlen und Buchstaben,
        die auf dem von Ihnen genutzten Endger&auml;t abgelegt und gespeichert
        werden. Cookies dienen vorrangig dazu, Informationen zwischen dem von
        Ihnen genutzten Endger&auml;t und unserer Webseite auszutauschen. Hierzu
        geh&ouml;ren u. a. die Spracheinstellungen auf einer Webseite, der
        Login-Status oder die Stelle, an der ein Video geschaut wurde.
      </p>
      <p>
        Beim Besuch unserer Webseiten werden zwei Typen von Cookies eingesetzt:
      </p>
      <ul>
        <li>
          <strong>Tempor&auml;re Cookies (Session Cookies):</strong>&nbsp;Diese
          speichern eine sogenannte Session-ID, mit welcher sich verschiedene
          Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Die
          Session-Cookies werden gel&ouml;scht, wenn Sie sich ausloggen oder
          Ihren Browser schlie&szlig;en.
        </li>
        <li>
          <strong>Permanente Cookies:&nbsp;</strong>Permanente Cookies bleiben
          auch nach dem Schlie&szlig;en des Browsers gespeichert. Dadurch
          erkennt unsere Webseite Ihren Rechner wieder, wenn Sie auf unsere
          Webseite zur&uuml;ckkehren. In diesen Cookies werden beispielsweise
          Informationen zu Spracheinstellungen oder Log-In-Informationen
          gespeichert. Au&szlig;erdem kann mit diesen Cookies Ihr Surfverhalten
          dokumentiert und gespeichert werden. Diese Daten k&ouml;nnen zu
          Statistik-, Marketing- und Personalisierungs-Zwecken verwendet werden.
        </li>
      </ul>
      <p>
        Neben der vorstehenden Einteilung k&ouml;nnen Cookies auch im Hinblick
        auf ihren Einsatzzweck unterschieden werden:
      </p>
      <ul>
        <li>
          <strong>Notwendige Cookies:</strong>&nbsp;Dies sind Cookies, die
          f&uuml;r den Betrieb unserer Webseite unbedingt erforderlich sind, um
          Logins oder Warenk&ouml;rbe f&uuml;r die Dauer Ihrer Sitzung zu
          speichern oder Cookies, die aus Sicherheitsgr&uuml;nden gesetzt
          werden.
        </li>
        <li>
          <strong>Statistik-, Marketing- und Personalisierungs-Cookies:</strong>
          &nbsp;Dies sind Cookies, die f&uuml;r Analysezwecke oder die
          Reichweitenmessung eingesetzt werden. &Uuml;ber solche
          "Tracking"-Cookies k&ouml;nnen insbesondere Informationen zu
          eingegebenen Suchbegriffen oder die H&auml;ufigkeit von Seitenaufrufen
          gespeichert sein. Daneben kann aber auch das Surfverhalten eines
          einzelnen Nutzers (z. B. Betrachten bestimmter Inhalte, Nutzen von
          Funktionen etc.) in einem Nutzerprofil gespeichert werden. Solche
          Profile werden genutzt, um Nutzern Inhalte anzuzeigen, die deren
          potentiellen Interessen entsprechen. Soweit wir Dienste nutzen,
          &uuml;ber die Cookies zu Statistik-, Marketing- und
          Personalisierungs-Zwecken auf Ihrem Endger&auml;t gespeichert werden,
          informieren wir Sie hierzu gesondert in den nachfolgenden Abschnitten
          unserer Datenschutzerkl&auml;rung oder im Rahmen der Einholung Ihrer
          Einwilligung.
        </li>
      </ul>
      <h3>
        <strong>Betroffene Daten:</strong>
      </h3>
      <ul>
        <li>Nutzungsdaten (bspw. Zugriffszeiten, angeklickte Webseiten)</li>
        <li>
          Kommunikationsdaten (bspw. Informationen &uuml;ber das genutzte
          Ger&auml;t, IP-Adresse).
        </li>
      </ul>
      <div>
        <h3 style={{ display: "inline-block" }}>
          <strong>Betroffene Personen:&nbsp;</strong>
        </h3>
        <span>Nutzer unserer Onlineangebote</span>
      </div>
      <div>
        <h3 style={{ display: "inline-block" }}>
          <strong>Verarbeitungszweck:&nbsp;</strong>
        </h3>
        <span>
          Ausspielen unserer Internetseiten, Gew&auml;hrleistung des Betriebs
          unserer Internetseiten, Verbesserung unseres Internetangebotes,
          Kommunikation und Marketig
        </span>
      </div>
      <p>
        <strong>
          <h3>Rechtsgrundlage:</h3>
          <br /> Berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO
        </strong>
        <br /> Sofern wir von Ihnen keine Einwilligung in das Setzen der Cookies
        einholen, st&uuml;tzen wir die Verarbeitung Ihrer Daten auf unser
        berechtigtes Interesse, die Qualit&auml;t und Benutzerfreundlichkeit
        unseres Internetauftritts, insbesondere der Inhalte und Funktionen zu
        verbessern. Sie haben &uuml;ber die Sicherheitseinstellungen Ihres
        Browsers, dem Einsatz der von uns im Rahmen unseres berechtigten
        Interesses gesetzten Cookies zu widersprechen. Dort haben Sie die
        M&ouml;glichkeit festzulegen, ob Sie etwa von vornherein keine oder nur
        auf Nachfrage Cookies akzeptieren oder aber festlegen, dass Cookies nach
        jedem Schlie&szlig;en Ihres Browsers gel&ouml;scht werden. Werden
        Cookies f&uuml;r unsere Webseite deaktiviert, k&ouml;nnen
        m&ouml;glicherweise nicht mehr alle Funktionen der Webseite
        vollumf&auml;nglich genutzt werden.
      </p>
      <p>
        <strong>Einwilligung, Art. 6 Abs. 1 lit. a DSGVO</strong>
        <br /> Sofern wir Sie vor Ihrem Besuch unserer Internetpr&auml;senz
        darum bitten, bestimmte Cookies auf Ihr Endger&auml;t setzen zu
        d&uuml;rfen, und Sie hierein einwilligen, ist in der von Ihnen erteilten
        Einwilligung die Rechtsgrundlage zu sehen. Wir informieren Sie im Rahmen
        der Einwilligung dar&uuml;ber, welche Cookies wir im Einzelnen setzen.
        Sofern Sie diese Einwilligung nicht erteilen, setzen sich lediglich die
        sogenannten technisch notwendigen Cookies, die f&uuml;r den
        ordnungsgem&auml;&szlig;en Betrieb unserer Internetseiten sowie deren
        Darstellung in Ihrem Browser erforderlich sind. Sofern Sie in das Setzen
        von Cookies eingewilligt haben, haben die jederzeitige M&ouml;glichkeit,
        uns gegen&uuml;ber der erteilten Einwilligung zu widerrufen.
      </p>
      <h3>
        <strong>Webhosting</strong>
      </h3>
      <p>
        Wir bedienen uns zum Vorhalten unserer Internetseiten eines Anbieters,
        auf dessen Server unsere Internetseiten gespeichert und f&uuml;r den
        Abruf im Internet verf&uuml;gbar gemacht werden (Hosting). Hierbei
        k&ouml;nnen von dem Anbieter all diejenigen &uuml;ber den von Ihnen
        genutzten Browser &uuml;bertragenen Daten verarbeitet werden, die bei
        der Nutzung unserer Internetseiten anfallen. Hierzu geh&ouml;ren
        insbesondere Ihre IP-Adresse, die der Anbieter ben&ouml;tigt, um unser
        Online-Angebot an den von Ihnen genutzten Browser ausliefern zu
        k&ouml;nnen sowie s&auml;mtliche von Ihnen &uuml;ber unsere
        Internetseite get&auml;tigten Eingaben. Daneben kann der von uns
        genutzte Anbieter
      </p>
      <ul>
        <li>das Datum und die Uhrzeit des Zugriffs auf unsere Internetseite</li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Zugriffsstatus (HTTP-Status)</li>
        <li>die &uuml;bertragene Datenmenge</li>
        <li>der Internet-Service-Provider des zugreifenden Systems</li>
        <li>der von Ihnen verwendete Browsertyp und dessen Version</li>
        <li>das von Ihnen verwendete Betriebssystem</li>
        <li>
          die Internetseite, von welcher Sie gegebenenfalls auf unsere
          Internetseite gelangt sind
        </li>
        <li>
          die Seiten bzw. Unterseiten, welche Sie auf unserer Internetseite
          besuchen.
        </li>
      </ul>
      <p>
        erheben. Die vorgenannten Daten werden als Logfiles auf den Servern
        unseres Anbieters gespeichert. Dies ist erforderlich, um die
        Stabilit&auml;t und Sicherheit des Betriebs unserer Internetseite zu
        gew&auml;hrleisten.
      </p>
      <h3>
        <strong>Betroffene Daten:</strong>
      </h3>
      <ul>
        <li>Inhaltsdaten (bspw. Posts, Fotos, Videos)</li>
        <li>Nutzungsdaten (bspw. Zugriffszeiten, angeklickte Webseiten)</li>
        <li>
          Kommunikationsdaten (bspw. Informationen &uuml;ber das genutzte
          Ger&auml;t, IP-Adresse)
        </li>
      </ul>
      <p>
        <strong>Betroffene Personen:&nbsp;</strong>Nutzer unserer
        Internetpr&auml;senz
      </p>
      <p>
        <strong>Verarbeitungszweck:&nbsp;</strong>Ausspielen unserer
        Internetseiten, Gew&auml;hrleistung des Betriebs unserer Internetseiten
      </p>
      <p>
        <strong>Rechtsgrundlage:</strong>&nbsp;Berechtigtes Interesse, Art. 6
        Abs. 1 lit. f DSGVO
      </p>
      <p>
        <strong>Von uns beauftragte(r) Webhoster:</strong>
      </p>
      <h3>
        <strong>Kontaktaufnahme</strong>
      </h3>
      <p>
        Soweit Sie uns &uuml;ber E-Mail, Soziale Medien, Telefon, Fax, Post,
        unser Kontaktformular oder sonstwie ansprechen und uns hierbei
        personenbezogene Daten wie Ihren Namen, Ihre Telefonnummer oder Ihre
        E-Mail-Adresse zur Verf&uuml;gung stellen oder weitere Angaben zur Ihrer
        Person oder Ihrem Anliegen machen, verarbeiten wir diese Daten zur
        Beantwortung Ihrer Anfrage im Rahmen des zwischen uns bestehenden
        vorvertraglichen oder vertraglichen Beziehungen.
      </p>
      <h3>
        <strong>Betroffene Daten:</strong>
      </h3>
      <ul>
        <li>Bestandsdaten (bspw. Namen, Adressen)</li>
        <li>
          Kontakdaten (bspw. E-Mail-Adresse, Telefonnummer, Postanschrift)
        </li>
        <li>Inhaltsdaten (Texte, Fotos, Videos)</li>
        <li>Vertragsdaten (bspw. Vertragsgegenstand, Vertragsdauer)</li>
      </ul>
      <p>
        <strong>Betroffene Personen:&nbsp;</strong>Interessenten, Kunden,
        Gesch&auml;fts- und Vertragspartner
      </p>
      <p>
        <strong>Verarbeitungszweck:&nbsp;</strong>Kommunikation sowie
        Beantwortung von Kontaktanfragen, B&uuml;ro und Organisationsverfahren
      </p>
      <p>
        <strong>Rechtsgrundlage:</strong>&nbsp;Vertragserf&uuml;llung und
        vorvertragliche Anfragen, Art. 6 Abs. 1 lit. b DSGVO, berechtigtes
        Interesse, Art. 6 Abs. 1 lit. f DSGVO
      </p>
      <p>
        <strong>Der Umgang mit Ihren Daten im Bewerbungsverfahren</strong>
      </p>
      <p>
        Sofern Sie sich bei uns bewerben, verarbeiten wir die von Ihnen im
        Bewerbungsverfahren an uns &uuml;bermittelten personenbezogenen Daten
        wie bspw. Ihren Name, Adresse, Wohnort, Alter, Bewerbungsfoto, E-Mail
        und Telefonnummer, Beruflicher Werdegang inklusive Schulen, Ausbildung,
        Studium. Sofern Sie die Daten per E-Mail oder &uuml;ber ein
        Kontaktformular unseres Online-Auftritts senden, erfolgt die
        Verarbeitung auf elektronischem Wege. Bei einer &Uuml;bersendung Ihrer
        Bewerbung &uuml;ber das Kontaktformular ist die &Uuml;bertragung Ihrer
        Daten nach dem Stand der Technik verschl&uuml;sselt. Sofern Sie Ihre
        Daten per E-Mail &uuml;bersenden, weisen wir daraufhin, dass die
        &Uuml;bertragung in der Regel unverschl&uuml;sselt erfolgt. Kommt es im
        Anschluss des Bewerbungsverfahrens zum Abschluss eines Arbeitsvertrages,
        speichern wir Ihre Daten zum Zwecke der Abwicklung des
        Besch&auml;ftigungsverh&auml;ltnisses unter Beachtung der gesetzlichen
        Vorschriften.
      </p>
      <h3>
        <strong>Betroffene Daten:</strong>
      </h3>
      <ul>
        <li>Bestandsdaten (bspw. Namen, Adressen)</li>
        <li>Zahlungsdaten (bspw. Bankverbindungsdaten, Rechnungen)</li>
        <li>
          Kontakdaten (bspw. E-Mail-Adresse, Telefonnummer, Postanschrift)
        </li>
        <li>Vertragsdaten (bspw. Vertragsgegenstand, Vertragsdauer)</li>
      </ul>
      <p>
        <strong>Betroffene Personen:&nbsp;</strong>Bewerber und Bewerberinnen
      </p>
      <p>
        <strong>Verarbeitungszweck:&nbsp;</strong>Abwicklung des
        Bewerbungsverfahrens
      </p>
      <p>
        <strong>Rechtsgrundlage:</strong>&nbsp;Vertragserf&uuml;llung und
        vorvertragliche Anfragen, Art. 6 Abs. 1 lit. b DSGVO, rechtliche
        Verpflichtung, Art. 6 Abs. 1 lit. c DSGVO
      </p>
      <p>
        <strong>L&ouml;schung:</strong>&nbsp;Kommt es nicht zu dem Abschluss
        eines Arbeitsvertrages, werden Ihre Daten nach Abschluss des
        Bewerbungsverfahrens bzw. sp&auml;testens 2 Monate nach dessen Abschluss
        gel&ouml;scht. Dies gilt nicht, sofern gesetzliche Bestimmungen der
        L&ouml;schung entgegenstehen oder die weitere Speicherung Ihrer Daten
        zum Zwecke der Beweisf&uuml;hrung erforderlich ist, etwa in einem
        Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG). Das
        Bewerbungsverfahren gilt mit der Absendung der Absage an Sie als
        abgeschlossen.
      </p>
      <h3>
        <strong>Online-Werbung</strong>
      </h3>
      <p>
        Wir nutzen Dienste zum Ausspielen von Internetwerbung. &Uuml;ber die von
        uns genutzten Dienste werden durch einen Cookie oder Pixel bestimmte
        Nutzerdaten erhoben. Hierzu z&auml;hlen insbesondere die Information von
        welcher Internetseite Sie auf unsere Internetpr&auml;senz gelangt sind
        (sogenannte Referrer), auf welche Seiten unserer Internetpr&auml;senz
        Sie zugegriffen haben, wie lange Sie unsere Seiten besucht und welche
        Interaktionen Sie dort vorgenommen haben. Daneben werden Daten zu dem
        von Ihnen verwendeten Browser, Computersystem sowie der Ger&auml;teart
        erhoben. Au&szlig;erdem k&ouml;nnen &uuml;ber einen solchen Dienst auch
        demographische Informationen, wie bspw. das Alter oder das Geschlecht
        als pseudonyme Werte erfasst werden. Sofern Sie in die Erhebung Ihrer
        Standortdaten eingewilligt haben, k&ouml;nnen, je nach Anbieter, auch
        diese verarbeitet werden. Um diese Daten zu erfassen und zu speichern,
        setzt der jeweilige Dienst einen Cookie bzw. einen sogenannten
        Z&auml;hl-Pixel auf das von Ihnen genutzte Endger&auml;t, mit dem auch
        die Ihnen zugeordnete IP-Adresse erhoben wird. Allerdings wird diese
        &uuml;ber ein sogenanntes IP-Masking-Verfahren gek&uuml;rzt, so dass die
        IP-Adresse nicht mehr Ihrem Besuch auf unserer Internetseite zugeordnet
        werden kann. Grunds&auml;tzlich werden beim Einsatz des jeweiligen
        Dienstes keine Klardaten wie Namen oder E-Mail-Adressen gespeichert.
        Dies ist nur dann der Fall, wenn Sie Mitglied eines sozialen Netzwerks
        sind, das einen der nachstehend genannten Dienste anbietet und Ihr
        Profil mit dem vorgenannten Datenmaterial zusammenf&uuml;hrt.
      </p>
      <p>
        Die Daten werden von dem von uns eingesetzte Dienst ausgewertet, um
        daraus einen Bericht mit statistischen Aussagen &uuml;ber die Anzahl der
        &uuml;ber die Werbung generierten Besucher und den Erfolg der
        Werbema&szlig;nahme zu fertigen. In den Berichten werden unter anderem
        die Gesamtanzahl der Nutzer, die &uuml;ber unsere Anzeigen auf unsere
        Webseite weitergeleitet wurden ausgewiesen. Daneben enthalten die
        Berichte Informationen zu den Endger&auml;ten und Browsern
        der&nbsp;Nutzer, an welchen Standorten sich die Nutzer befanden, zu
        welchen Zeiten die Werbeanzeige geklickt wurde. In den Berichten sind
        jedoch keine Informationen enthalten, mit denen Sie sich als Nutzer
        unserer Seite pers&ouml;nlich identifizieren lie&szlig;en.
      </p>
      <p>
        Wir m&ouml;chten Sie darauf hinweisen, dass je nach Sitz des
        Diensteanbieters die &uuml;ber den Dienst erfassten Daten
        au&szlig;erhalb des Raumes der Europ&auml;ischen Union &uuml;bertragen
        und verarbeitet werden k&ouml;nnen. Es besteht in diesem Fall das
        Risiko, dass das von der DSGVO vorgeschriebene Datenschutzniveau nicht
        eingehalten und die Durchsetzung Ihrer Rechte nicht oder nur erschwert
        erfolgen kann.
      </p>
      <h3>
        <strong>Betroffene Daten:</strong>
      </h3>
      <ul>
        <li>Nutzungsdaten (bspw. Zugriffszeiten, angeklickte Webseiten)</li>
        <li>
          Kommunikationsdaten (bspw. Informationen &uuml;ber das genutzte
          Ger&auml;t, IP-Adresse)
        </li>
      </ul>
      <p>
        <strong>Betroffene Personen:&nbsp;</strong>Nutzer unserer Onlineangebote
      </p>
      <p>
        <strong>Verarbeitungszweck:&nbsp;</strong>Reichweitenmessung,
        Erfolgskontrolle von Kampagnen, Remarketing sowie interessen- und
        verhaltensbedingtes Marketing
      </p>
      <p>
        <strong>Rechtsgrundlage:</strong>&nbsp;Sofern wir Sie vor dem Einsatz
        des jeweiligen Dienstes um Ihre Einwilligung gebeten haben, liegt hierin
        die Rechtsgrundlage, Art. 6 Abs. 1 lit. a DSGVO. Im &Uuml;brigen setzen
        wir den jeweiligen Dienst auf Grund unseres berechtigten Interesses ein,
        Besucherstr&ouml;me auf unsere Internetseite zu leiten, diese
        Besucherstr&ouml;me zu analysieren, um hier&uuml;ber die Funktionen,
        Angebote sowie das Nutzungserlebnis fortlaufend verbessern zu
        k&ouml;nnen, Art. 6 Abs. 1 lit. f DSGVO.
      </p>
      <h3>
        <strong>Wir nutzen zur Online-Werbung folgende Diensteanbieter:</strong>
      </h3>
      <h3>
        <strong>Google Ads</strong>
      </h3>
      <p>
        Dienstanbieter: Google Inc., 1600 Amphitheatre Parkway, Mountain View,
        CA 94043, USA
        <br /> Sitz in Europa: Google Dublin, Google Ireland Ltd., Gordon House,
        Barrow Street, Dublin 4, Irland
        <br /> Internetseite:&nbsp;
        <a href="https://ads.google.com/home/">https://ads.google.com/home/</a>
        <br /> Datenschutzerkl&auml;rung:
        <a href="https://www.google.de/intl/de/policies/privacy">
          &nbsp;http://www.google.de/intl/de/policies/privacy
        </a>
      </p>
      <h3>
        <strong>Werbung per E-Mail, Post oder Telefon</strong>
      </h3>
      <p>
        F&uuml;r unsere werbliche Kommunikation per E-Mail, Post oder Telefon
        verarbeiten wir personenbezogene Daten. Sie k&ouml;nnen dem Erhalt
        unserer Werbema&szlig;nahmen jeder Zeit widersprechen oder die zuvor
        erteilte Einwilligung zum Erhalt unserer werblichen Kommunikation zu
        jeder Zeit widerrufen. Um auch nach Ihrem Widerspruch bzw. Widerruf im
        Zweifelsfall nachweisen zu k&ouml;nnen, dass Ihre Einwilligung vorlag,
        k&ouml;nnen wir Ihre Daten nach Ihrem Widerspruch/Widerruf bis zu 4
        Jahre speichern. F&uuml;r weitere Zwecke verwenden wir Ihre Daten nach
        Ihrem Widerspruch/Widerruf nicht mehr. Sofern Sie wollen, dass wir Ihre
        Daten vorher l&ouml;schen, werden wir das tun, nachdem Sie uns
        best&auml;tigt haben, dass Sie uns urspr&uuml;nglich eine Einwilligung
        erteilt haben.
      </p>
      <p>
        <strong>Betroffene Daten:</strong>
      </p>
      <ul>
        <li>Kontaktdaten (z.B. E-Mail, Telefonnummer, Postadresse)</li>
        <li>Bestandsdaten (z.B. Namen, Adressen)</li>
      </ul>
      <p>
        <strong>Betroffene Personen:&nbsp;</strong>Kommunikationspartner
      </p>
      <p>
        <strong>Verarbeitungszweck:&nbsp;</strong>Direkte Werbema&szlig;nahmen
        (Marketing) per E-Mail, Post oder Telefon
      </p>
      <p>
        <strong>Rechtsgrundlage:</strong>&nbsp;Einwilligung, Art. 6 Abs. 1 lit.
        a DSGVO, berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO
      </p>
      <h3>
        <strong>Unsere Onlinepr&auml;senzen bei sozialen Netzwerken</strong>
      </h3>
      <p>
        Wir betreiben Onlinepr&auml;senzen innerhalb der nachfolgend
        aufgelisteten sozialen Netzwerke. Besuchen Sie eine dieser
        Pr&auml;senzen, werden durch den jeweiligen Anbieter die unten
        n&auml;her aufgef&uuml;hrten Daten erhoben und verarbeitet. In der Regel
        werden diese Daten zur Werbe- und Marktforschungszwecke erhoben und
        hiermit Nutzungsprofile angelegt. In den Nutzungsprofilen k&ouml;nnen
        Daten unabh&auml;ngig des von Ihnen verwendeten Ger&auml;tes gespeichert
        werden. Dies ist insbesondere dann der Fall, wenn Sie Mitglied der
        jeweiligen Plattform und bei dieser eingeloggt sind. Die Nutzungsprofile
        k&ouml;nnen von den Anbietern dazu verwendet werden, um Ihnen
        interessenbezogene Werbung auszuspielen. Gegen die Erstellung von
        Nutzerprofilen steht Ihnen ein Widerrufsrecht zu. Um dieses
        auszu&uuml;ben, m&uuml;ssen Sie sich an den jeweiligen Anbieter wenden.
      </p>
      <p>
        Wenn Sie einen Account bei einem der unten aufgef&uuml;hrten Anbieter
        besitzen und beim Besuch unserer Webseite dort eingeloggt sind, kann der
        jeweilige Anbieter Daten &uuml;ber Ihr Nutzungsverhalten auf unserer
        Webseite erheben. Um eine solche Verkn&uuml;pfung Ihrer Daten zu
        verhindern, k&ouml;nnen Sie sich vor dem Besuch unserer Seite bei dem
        Dienst des Anbieters ausloggen.
      </p>
      <p>
        Zu welchem Zweck und in welchem Umfang Daten von dem Anbieter erhoben
        werden, k&ouml;nnen Sie den jeweiligen, im Folgenden mitgeteilten,
        Datenschutzerkl&auml;rungen der Anbieter entnehmen.
      </p>
      <p>
        Wir m&ouml;chten Sie darauf hinweisen, dass je nach Sitzland des unten
        genannten Anbieters die &uuml;ber dessen Plattform erfassten Daten
        au&szlig;erhalb des Raumes der Europ&auml;ischen Union &uuml;bertragen
        und verarbeitet werden k&ouml;nnen. Es besteht in diesem Fall das
        Risiko, dass das von der DSGVO vorgeschriebene Datenschutzniveau nicht
        eingehalten und die Durchsetzung Ihrer Rechte nicht oder nur erschwert
        erfolgen kann.
      </p>
      <p>
        <strong>Betroffene Daten:</strong>
      </p>
      <ul>
        <li>
          Bestands- und Kontaktdaten (bspw. Name, Adresse, Telefonnummer,
          E-Mail-Adresse)
        </li>
        <li>Inhaltsdaten (bspw. Posts, Fotos, Videos)</li>
        <li>Nutzungsdaten (bspw. Zugriffszeiten, angeklickte Webseiten)</li>
        <li>
          Kommunikationsdaten (bspw. Informationen &uuml;ber das genutzte
          Ger&auml;t, IP-Adresse).
        </li>
      </ul>
      <p>
        <strong>Verarbeitungszweck:&nbsp;</strong>Kommunikation und Marketing,
        Verfolgen und Anaylse von Nutzerverhalten
      </p>
      <p>
        <strong>Rechtsgrundlage:</strong>&nbsp;Einwilligung, Art. 6 Abs. 1 lit.
        a DSGVO, berechtigtes Interessen Art. 6 Abs. 1 lit. f DSGVO
      </p>
      <p>
        <strong>Widerspruchsm&ouml;glichkeiten:&nbsp;</strong>Zu den jeweiligen
        Widerspruchsm&ouml;glichkeiten (Opt-Out) verweisen wir auf die
        nachfolgend verlinkten Angaben der Anbieter.
      </p>
      <h3>
        <strong>
          Wir unterhalten Onlinepr&auml;senzen auf folgenden sozialen
          Netzwerken:
        </strong>
      </h3>
      <p>
        <strong>Facebook</strong>
      </p>
      <p>
        Dienstanbieter: Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA
        <br /> Sitz in der EU: Facebook Ireland Ltd., 4 Grand Canal Square,
        Grand Canal Harbour, Dublin 2, Irland&nbsp;
        <br /> Internetseite:&nbsp;
        <a href="https://www.facebook.com/">https://www.facebook.com/</a>
        <br /> Datenschutzerkl&auml;rung:&nbsp;
        <a href="https://www.facebook.com/about/privacy/">
          https://www.facebook.com/about/privacy/
        </a>
        <br /> Datenschutzerkl&auml;rung f&uuml;r Facebook-Seiten:&nbsp;
        <a href="https://www.facebook.com/legal/terms/information_about_page_insights_data">
          https://www.facebook.com/legal/terms/information_about_page_insights_data
        </a>
      </p>
      <p>
        <strong>Instagram</strong>
      </p>
      <p>
        Dienstanbieter: Instagram Inc., 1601 Willow Road, Menlo Park CA 94025,
        USA
        <br /> Mutterunternehmen: Facebook Inc., 1 Hacker Way, Menlo Park, CA
        94025, USA
        <br /> Sitz in der EU: Facebook Ireland Limited, 4 Grand Canal Square,
        Dublin 2, Ireland
        <br /> Internetseite:&nbsp;
        <a href="https://www.instagram.com/">https://www.instagram.com/</a>
        <br /> Datenschutzerkl&auml;rung:&nbsp;
        <a href="https://instagram.com/about/legal/privacy">
          http://instagram.com/about/legal/privacy
        </a>
      </p>
      <p>
        <strong>Sicherheitsma&szlig;nahmen</strong>
      </p>
      <p>
        Wir treffen im &Uuml;brigen technische und organisatorische
        Sicherheitsma&szlig;nahmen nach dem Stand der Technik, um die
        Vorschriften der Datenschutzgesetze einzuhalten und Ihre Daten gegen
        zuf&auml;llige oder vors&auml;tzliche Manipulationen, teilweisen oder
        vollst&auml;ndigen Verlust, Zerst&ouml;rung oder gegen den unbefugten
        Zugriff Dritter zu sch&uuml;tzen.
      </p>
      <h3>
        <strong>
          Aktualit&auml;t und &Auml;nderung dieser Datenschutzerkl&auml;rung
        </strong>
      </h3>
      <p>
        Diese Datenschutzerkl&auml;rung ist aktuell g&uuml;ltig und hat den
        Stand November 2022. Aufgrund ge&auml;nderter gesetzlicher bzw.
        beh&ouml;rdlicher Vorgaben kann es notwendig werden, diese
        Datenschutzerkl&auml;rung anzupassen.
      </p>
    </div>
  );
}
