import React, { useEffect, useState } from "react";
import { Router, useHistory } from "react-router-dom";
import ContactBanner from "../../assets/images/newmap.png";
import Swal from "sweetalert2";
import "./kontaktForm.scss";
import axios from "axios";
import { forEmail } from "../../config";

export default function KontaktForm() {
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const validateFormforAnfrage = () => {
    let formIsValid = true;
    let errors: any = {};
    if (formData && !formData.Anrede?.trim()) {
      formIsValid = false;
      errors["Anrede"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Vorname?.trim()) {
      formIsValid = false;
      errors["Vorname"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Nachname?.trim()) {
      formIsValid = false;
      errors["Nachname"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.email) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (!regex.test(formData.email)) {
      formIsValid = false;
      errors["email"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Ruckrufnummer) {
      formIsValid = false;
      errors["Ruckrufnummer"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (formData && !formData.Nachricht?.trim()) {
      formIsValid = false;
      errors["Nachricht"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (validateFormforAnfrage()) {
      const DataToSend = {
        from: "noreply@jesamconsulting.com",
        to: forEmail,
        subject: "Render Kings",
        html: `    
            <strong>Render Kings</strong> <br />
            <strong>Anrede </strong> ${formData.Anrede} <br />
            <strong>Vorname </strong> ${formData.Vorname} <br />
            <strong>Nachname </strong> ${formData.Nachname} <br />
            <strong>E-Mail: </strong> ${formData.email} <br />
            <strong>Telefon: </strong> ${formData.Telefon} <br />
            <strong>Ruckrufnummer: </strong> ${formData.Ruckrufnummer} <br />
            <strong>Nachricht: </strong> ${formData.Nachricht} <br />
                   
        `,
      };

      const remoteServerUrl =
        "https://jesam-mail-api.herokuapp.com/api/sendemail";

      axios
        .post(remoteServerUrl, DataToSend)
        .then((res) => {
          console.log("Message send success");
          setFormData({
            Vorname: "",
            email: "",
            Telefon: "",
            Anrede: "",
            Nachricht: "",
            Ruckrufnummer: "",
            Nachname: "",
          });

          Swal.fire({
            title: "Vielen Dank \n" + "für Ihre Anfrage",
            icon: "success",
            confirmButtonText: "Bestätigen",
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Something happened",
            text: "Please try later",
            icon: "error",
            confirmButtonText: "Bestätigen",
          });
          console.log(err);
        });
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  return (
    <div>
      <div className="form-banner-content-alignment">
        <div className="container-lg-7">
          <div className="container-map">
            <iframe
              width="100%"
              height="100%"
              scrolling="no"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Insterburger%20Stra%C3%9Fe%205,%2028207%20Bremen+()&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
              <a href="https://www.maps.ie/distance-area-calculator.html">
                area maps
              </a>
            </iframe>
          </div>
        </div>
      </div>
      <div className="container-md">
        <div className="form-new-box-center-alignment-for-page">
          <div className="contact-box">
            <div className="contact-child-box">
              <h1
                className="animate__animated animate__slideInUp wow"
                data-wow-offset="100">
                Kontakt
              </h1>
              <p
                className="animate__animated animate__slideInUp wow"
                data-wow-offset="100">
                Wir freuen uns auf Ihre Nachricht über das Kontaktformular
                unten, oder Ihren Anruf unter{" "} <br/>
                <a href="tel: 0421 64 85 29 66">0421 64 85 29 66</a>
              </p>

              <div className="input">
                {/* <input
                  type="text"
                  name="Anrede"
                  placeholder="Anrede*"
                  value={formData.Anrede && formData.Anrede}
                  onChange={(e) => handleChange(e)}
                /> */}
                <div className="floating-form">
                  <div className="floating-label">
                    <input
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      id="Anrede"
                      name="Anrede"
                      value={formData.Anrede && formData.Anrede}
                      onChange={(e) => handleChange(e)}
                    />

                    <label>Anrede</label>
                    <span style={{ fontSize: "10px", color: "red" }}>
                      {errors.Anrede}
                    </span>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="input"></div>
                <div className="two-col">
                  <div className="input">
                    {/* <input
                      type="text"
                      name="Vorname"
                      placeholder="Vorname"
                      value={formData.Vorname && formData.Vorname}
                      onChange={(e) => handleChange(e)}
                    />
                    <span style={{ fontSize: "10px", color: "red" }}>
                      {errors.Vorname}
                    </span> */}
                    <div className="floating-form">
                      <div className="floating-label">
                        <input
                          className="floating-input"
                          type="text"
                          placeholder=" "
                          id="Vorname"
                          name="Vorname"
                          value={formData.Vorname && formData.Vorname}
                          onChange={(e) => handleChange(e)}
                        />

                        <label>Vorname</label>
                        <span style={{ fontSize: "10px", color: "red" }}>
                          {errors.Vorname}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="input">
                    {/* <input
                      type="text"
                      name="Nachname"
                      placeholder="Nachname"
                      value={formData.Nachname && formData.Nachname}
                      onChange={(e) => handleChange(e)}
                    />
                    <span style={{ fontSize: "10px", color: "red" }}>
                      {errors.Nachname}
                    </span> */}
                    <div className="floating-form">
                      <div className="floating-label">
                        <input
                          className="floating-input"
                          type="text"
                          placeholder=" "
                          id="Nachname"
                          name="Nachname"
                          value={formData.Nachname && formData.Nachname}
                          onChange={(e) => handleChange(e)}
                        />

                        <label>Nachname</label>
                        <span style={{ fontSize: "10px", color: "red" }}>
                          {errors.Nachname}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input">
                {/* <input
                  type="text"
                  name="email"
                  placeholder="E-mail"
                  value={formData.email && formData.email}
                  onChange={(e) => handleChange(e)}
                />
                <span style={{ fontSize: "10px", color: "red" }}>
                  {errors.email}
                </span> */}
                <div className="floating-form">
                  <div className="floating-label">
                    <input
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      id="E-mail"
                      name="email"
                      value={formData.email && formData.email}
                      onChange={(e) => handleChange(e)}
                    />

                    <label>E-Mail</label>
                    <span style={{ fontSize: "10px", color: "red" }}>
                      {errors.email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="input">
                {/* <input
                  type="number"
                  name="Ruckrufnummer"
                  placeholder="Rückrufnummer"
                  value={formData.Ruckrufnummer && formData.Ruckrufnummer}
                  onChange={(e) => handleChange(e)}
                />
                <span style={{ fontSize: "10px", color: "red" }}>
                  {errors.Ruckrufnummer}
                </span> */}
                <div className="floating-form">
                  <div className="floating-label">
                    <input
                      className="floating-input"
                      type="text"
                      placeholder=" "
                      id="Ruckrufnummer"
                      name="Ruckrufnummer"
                      value={formData.Ruckrufnummer && formData.Ruckrufnummer}
                      onChange={(e) => handleChange(e)}
                    />

                    <label>Ruckrufnummer</label>
                    <span style={{ fontSize: "10px", color: "red" }}>
                      {errors.Ruckrufnummer}
                    </span>
                  </div>
                </div>
              </div>

              <div className="input">
                {/* <textarea
                  name="Nachricht"
                  placeholder="Ihre Nachricht"
                  value={formData.Nachricht && formData.Nachricht}
                  onChange={(e) => handleChange(e)}
                ></textarea>
                <span style={{ fontSize: "10px", color: "red" }}>
                  {errors.Nachricht}
                </span> */}
                <div className="floating-form">
                  <div className="floating-label">
                    <textarea
                      className="floating-input"
                      placeholder=" "
                      id="Nachricht"
                      name="Nachricht"
                      value={formData.Nachricht && formData.Nachricht}
                      onChange={(e) => handleChange(e)}></textarea>
                    <span style={{ fontSize: "10px", color: "red" }}>
                      {errors.Nachricht}
                    </span>

                    <label>Nachricht</label>
                  </div>
                </div>
              </div>

              <div className="send-full-width" onClick={(e) => handleSubmit(e)}>
                <button>Senden</button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
